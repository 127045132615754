import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";

import navigationUtils from "./utils/navigation";
import Divider from "./components/Divider";
import Icon from "./components/Icon";
import Link from "./components/Link";
import Menu from "./components/Menu";
import Spinner from "./components/Spinner";
import { Museum } from "./types/museum";
import { Stores } from "./types/stores";

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  fetchUsers: () => Promise<void>;
  fetchTenants: () => Promise<void>;
  canFindTenants: boolean;
  canFindUsers: boolean;
  currentUserTenantName: string | null;
  fetchMuseums: () => Promise<void>;
  museums: Museum[];
  isLoading: boolean;
}

const mapStoresToProps = (stores: Stores): StoresProps => {
  const currentUserId = _.get(stores.auth.user, "objectId");
  const currentUserTenantId =
    _.get(stores.auth.user, "tenant.objectId") || null;
  const currentUserTenant =
    _.get(stores.tenants.tenants, currentUserTenantId) || null;
  return {
    fetchUsers: stores.users.fetchUsers,
    fetchTenants: stores.tenants.fetchTenants,
    canFindTenants: stores.users.getIsAdmin(currentUserId),
    canFindUsers:
      stores.users.getIsAdmin(currentUserId) ||
      (currentUserTenantId &&
        stores.users.getIsTenantAdmin(currentUserId, currentUserTenantId)),
    currentUserTenantName: currentUserTenant
      ? _.get(currentUserTenant, "name", "-")
      : null,
    fetchMuseums: stores.museums.fetchMuseums,
    museums: _.values(stores.museums.museums),
    isLoading:
      !stores.users.users ||
      !stores.users.roles ||
      !stores.tenants.tenants ||
      !stores.museums.museums
  };
};

const Sidebar = ({
  fetchUsers,
  fetchTenants,
  canFindTenants,
  canFindUsers,
  currentUserTenantName,
  fetchMuseums,
  museums,
  isLoading
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchMuseums();
    fetchUsers();
    fetchTenants();
  }, [fetchMuseums, fetchUsers, fetchTenants]);
  let defaultSelectedKeys: string[] = [];
  // TODO: get info about current location and choose default selected menu items
  // const museumId = navigationUtils.fromRoutes.museumId(location.pathname);
  // if (museumId) {
  //   if (location.pathname.includes("/artworks")) {
  //     defaultSelectedKeys = [`${museumId}_artworks`];
  //   } else if (location.pathname.includes("/routes")) {
  //     defaultSelectedKeys = [`${museumId}_routes`];
  //   } else if (location.pathname.includes("/analytics")) {
  //     defaultSelectedKeys = [`${museumId}_analytics`];
  //   } else {
  //     defaultSelectedKeys = [`${museumId}_museum`];
  //   }
  // }
  return (
    <Menu
      defaultSelectedKeys={defaultSelectedKeys}
      style={{ height: "100%", overflow: "auto" }}
    >
      <Menu.ItemGroup
        title={currentUserTenantName != null ? currentUserTenantName : "Menu"}
        style={{ marginTop: "0.4em" }}
      >
        {canFindTenants && (
          <Menu.Item key={"tenants"}>
            <Link to={navigationUtils.routes.tenant.list()}>
              <span>
                <Icon type="cluster" />
                <span>Clienti</span>
              </span>
            </Link>
          </Menu.Item>
        )}
        {canFindUsers && (
          <Menu.Item key={"users"}>
            <Link to={navigationUtils.routes.user.list()}>
              <span>
                <Icon type="team" />
                <span>Utenti</span>
              </span>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key={"museums"}>
          <Link to={navigationUtils.routes.museum.list()}>
            <span>
              <Icon type="home" />
              <span>Musei</span>
            </span>
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Item disabled>
        <Divider />
      </Menu.Item>
      {isLoading ? (
        <Menu.Item>
          <Spinner />
        </Menu.Item>
      ) : (
        museums.map(museum => (
          <Menu.ItemGroup key={museum.objectId} title={museum.name.it}>
            <Menu.Item key={`${museum.objectId}_museum`}>
              <Link
                to={navigationUtils.routes.museum.find(
                  museum.tenant.objectId,
                  museum.objectId
                )}
              >
                <span>
                  <Icon type="info-circle" />
                  <span>Info museo</span>
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key={`${museum.objectId}_areas`}>
              <Link
                to={navigationUtils.routes.museum.area.list(
                  museum.tenant.objectId,
                  museum.objectId
                )}
              >
                <span>
                  <Icon type="global" />
                  <span>Aree e mappe</span>
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key={`${museum.objectId}_artworks`}>
              <Link
                to={navigationUtils.routes.artwork.list(
                  museum.tenant.objectId,
                  museum.objectId
                )}
              >
                <span>
                  <Icon type="picture" />
                  <span>Punti di interesse</span>
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key={`${museum.objectId}_routes`}>
              <Link
                to={navigationUtils.routes.museum.route.list(
                  museum.tenant.objectId,
                  museum.objectId
                )}
              >
                <span>
                  <Icon type="fork" />
                  <span>Itinerari</span>
                </span>
              </Link>
            </Menu.Item>
            {/* TODO: enable when completed */}
            {/* <Menu.Item key={`${museum.objectId}_analytics`}>
              <Link
                to={navigationUtils.routes.analytics.main(
                  museum.tenant.objectId,
                  museum.objectId
                )}
              >
                <span>
                  <Icon type="bar-chart" />
                  <span>Analytics</span>
                </span>
              </Link>
            </Menu.Item> */}
          </Menu.ItemGroup>
        ))
      )}
    </Menu>
  );
};

export default inject(mapStoresToProps)(observer(Sidebar));
