import React, { useEffect, useState } from "react";
import _ from "lodash";

import Audio from "./Audio";
import Image from "./Image";
import Video from "./Video";
import stringUtils from "../utils/string";
import { ArtworkMultimedia } from "../types/artwork";

interface Props {
  // src?: string | ArtworkMultimedia;
  src?: string;
  getSrc?: () => Promise<string>;
  style?: any;
}

export default ({ src, getSrc, style, ...restProps }: Props) => {
  const [fileSrc, setFileSrc] = useState<string>();

  useEffect(() => {
    const computeNewFileSrc = async () => {
      let newFileSrc: string | undefined;
      if (typeof src === "string") {
        newFileSrc = src;
      }
      if (getSrc) {
        newFileSrc = await getSrc();
      }
      setFileSrc(newFileSrc);
    };
    computeNewFileSrc();
  }, [src, getSrc, setFileSrc]);

  if (stringUtils.isVideoSrc(fileSrc)) {
    return (
      <div
        style={_.merge(
          {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center"
          },
          style
        )}
      >
        <Video {...restProps} src={fileSrc} />
      </div>
    );
  } else if (stringUtils.isAudioSrc(fileSrc)) {
    return (
      <div
        style={_.merge(
          {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center"
          },
          style
        )}
      >
        <Audio {...restProps} src={fileSrc} />
      </div>
    );
  } else if (stringUtils.isImageSrc(fileSrc)) {
    return (
      <div
        style={_.merge(
          {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center"
          },
          style
        )}
      >
        <Image
          {...restProps}
          src={fileSrc}
          alt={String(fileSrc).slice(0, 20)}
        />
      </div>
    );
  } else {
    return null;
  }
};
