import { action, observable, computed } from "mobx";
import _ from "lodash";

import loggerService from "../services/logger";
import parseService from "../services/parse";
import stringUtils from "../utils/string";
import AuthStore from "./Auth";

import {
  Museum,
  MuseumArea,
  MuseumRoute,
  MuseumRouteTag
} from "../types/museum";
import { Tenant } from "../types/tenant";

export default class MuseumsStore {
  @observable
  public isInitialized: boolean = false;
  @observable
  public isFetchingMuseums: boolean = false;
  @observable
  public isCreatingMuseums: boolean = false;
  @observable
  public isUpdatingMuseums: boolean = false;
  @observable
  public isDeletingMuseums: boolean = false;
  @observable
  public museums: { [museumId: string]: Museum } | null = null;
  @observable
  public selectedMuseumId: Museum["objectId"] | null = null;
  public authStore: AuthStore;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

  @action
  public initialize = async () => {
    this.isInitialized = true;
  };

  @action
  public fetchMuseums = async () => {
    let museums: Museum[] = [];
    this.isFetchingMuseums = true;
    try {
      museums = await parseService.fetchMuseums();
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.museums = _.keyBy(museums, "objectId");
    this.isFetchingMuseums = false;
  };

  @action
  public createMuseum = async (
    params: Partial<Museum>,
    tenantId: Tenant["objectId"]
  ): Promise<Museum | null> => {
    this.isCreatingMuseums = true;
    let createdMuseum: Museum | null = null;
    try {
      createdMuseum = await parseService.createMuseumForTenant(
        params,
        tenantId
      );
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.museums) {
      this.museums = {};
    }
    if (createdMuseum) {
      this.museums[createdMuseum.objectId] = createdMuseum;
    }
    this.isCreatingMuseums = false;
    return createdMuseum;
  };

  @action
  public updateMuseum = async (
    params: Partial<Museum>
  ): Promise<Museum | null> => {
    if (!params.objectId) {
      return null;
    }
    this.isUpdatingMuseums = true;
    let updatedMuseum: Museum | null = null;
    try {
      updatedMuseum = await parseService.updateMuseum(params);
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.museums) {
      this.museums = {};
    }
    if (!updatedMuseum) {
      delete this.museums[params.objectId];
    } else {
      this.museums[params.objectId] = updatedMuseum;
    }
    this.isUpdatingMuseums = false;
    return updatedMuseum;
  };

  @action
  public deleteMuseum = async (museumId: Museum["objectId"]) => {
    let success = false;
    this.isDeletingMuseums = true;
    try {
      await parseService.deleteMuseum(museumId);
      success = true;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.museums) {
      this.museums = {};
    }
    if (success) {
      delete this.museums[museumId];
    }
    this.isDeletingMuseums = false;
    return success;
  };

  @action
  public createMuseumArea = async (
    params: Partial<MuseumArea>,
    museumId: Museum["objectId"]
  ) => {
    const area = { ...params, id: stringUtils.uuidv4() } as MuseumArea;
    this.isUpdatingMuseums = true;
    let success = false;
    try {
      const museums = await parseService.fetchMuseums();
      const museumsById = _.keyBy(museums, "objectId");
      const museum = museumsById[museumId];
      if (museum) {
        const museumAreas = _.get(museum, "areas") || [];
        const newMuseumAreas = museumAreas.concat(area);
        museum.areas = newMuseumAreas;
        const updatedMuseum = await parseService.updateMuseum(museum);
        if (updatedMuseum) {
          success = true;
          museumsById[museumId] = updatedMuseum;
        }
      }
      this.museums = museumsById;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.isUpdatingMuseums = false;
    return success ? area : null;
  };

  @action
  public updateMuseumArea = async (
    params: Partial<MuseumArea>,
    museumId: Museum["objectId"]
  ) => {
    if (!params.id) {
      return null;
    }
    const area = params as MuseumArea;
    this.isUpdatingMuseums = true;
    let success = false;
    try {
      const museums = await parseService.fetchMuseums();
      const museumsById = _.keyBy(museums, "objectId");
      const museum = museumsById[museumId];
      if (museum) {
        const museumAreas = _.get(museum, "areas") || [];
        const newMuseumAreas = museumAreas.map(museumArea =>
          museumArea.id === area.id ? { ...museumArea, ...area } : museumArea
        );
        museum.areas = newMuseumAreas;
        const updatedMuseum = await parseService.updateMuseum(museum);
        if (updatedMuseum) {
          success = true;
          museumsById[museumId] = updatedMuseum;
        }
      }
      this.museums = museumsById;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.isUpdatingMuseums = false;
    return success ? area : null;
  };

  @action
  public deleteMuseumArea = async (
    id: MuseumArea["id"],
    museumId: Museum["objectId"]
  ) => {
    this.isUpdatingMuseums = true;
    let success = false;
    try {
      const museums = await parseService.fetchMuseums();
      const museumsById = _.keyBy(museums, "objectId");
      const museum = museumsById[museumId];
      if (museum) {
        const museumAreas = _.get(museum, "areas") || [];
        const newMuseumAreas = museumAreas.filter(area => area.id !== id);
        museum.areas = newMuseumAreas;
        const updatedMuseum = await parseService.updateMuseum(museum);
        if (updatedMuseum) {
          success = true;
          museumsById[museumId] = updatedMuseum;
        }
      }
      this.museums = museumsById;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.isUpdatingMuseums = false;
    return success;
  };

  @action
  public createMuseumRoute = async (
    params: Partial<MuseumRoute>,
    museumId: Museum["objectId"]
  ) => {
    const route = { ...params, id: stringUtils.uuidv4() } as MuseumRoute;
    this.isUpdatingMuseums = true;
    let success = false;
    try {
      const museums = await parseService.fetchMuseums();
      const museumsById = _.keyBy(museums, "objectId");
      const museum = museumsById[museumId];
      if (museum) {
        const museumRoutes = _.get(museum, "routes") || [];
        const newMuseumRoutes = museumRoutes.concat(route);
        museum.routes = newMuseumRoutes;
        const updatedMuseum = await parseService.updateMuseum(museum);
        if (updatedMuseum) {
          success = true;
          museumsById[museumId] = updatedMuseum;
        }
      }
      this.museums = museumsById;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.isUpdatingMuseums = false;
    return success ? route : null;
  };

  @action
  public updateMuseumRoute = async (
    params: Partial<MuseumRoute>,
    museumId: Museum["objectId"]
  ) => {
    if (!params.id) {
      return null;
    }
    const route = params as MuseumRoute;
    this.isUpdatingMuseums = true;
    let success = false;
    try {
      const museums = await parseService.fetchMuseums();
      const museumsById = _.keyBy(museums, "objectId");
      const museum = museumsById[museumId];
      if (museum) {
        const museumRoutes = _.get(museum, "routes") || [];
        const newMuseumRoutes = museumRoutes.map(museumRoute =>
          museumRoute.id === route.id
            ? { ...museumRoute, ...route }
            : museumRoute
        );
        museum.routes = newMuseumRoutes;
        const updatedMuseum = await parseService.updateMuseum(museum);
        if (updatedMuseum) {
          success = true;
          museumsById[museumId] = updatedMuseum;
        }
      }
      this.museums = museumsById;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.isUpdatingMuseums = false;
    return success ? route : null;
  };

  @action
  public deleteMuseumRoute = async (
    id: MuseumRoute["id"],
    museumId: Museum["objectId"]
  ) => {
    this.isUpdatingMuseums = true;
    let success = false;
    try {
      const museums = await parseService.fetchMuseums();
      const museumsById = _.keyBy(museums, "objectId");
      const museum = museumsById[museumId];
      if (museum) {
        const museumRoutes = _.get(museum, "routes") || [];
        const newMuseumRoutes = museumRoutes.filter(route => route.id !== id);
        museum.routes = newMuseumRoutes;
        const updatedMuseum = await parseService.updateMuseum(museum);
        if (updatedMuseum) {
          success = true;
          museumsById[museumId] = updatedMuseum;
        }
      }
      this.museums = museumsById;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.isUpdatingMuseums = false;
    return success;
  };

  @action
  public createMuseumRouteTag = async (
    params: Partial<MuseumRouteTag>,
    museumId: Museum["objectId"]
  ) => {
    const routeTag = { ...params, id: stringUtils.uuidv4() } as MuseumRouteTag;
    this.isUpdatingMuseums = true;
    let success = false;
    try {
      const museums = await parseService.fetchMuseums();
      const museumsById = _.keyBy(museums, "objectId");
      const museum = museumsById[museumId];
      if (museum) {
        const museumRouteTags = _.get(museum, "routeTags") || [];
        const newMuseumRouteTags = museumRouteTags.concat(routeTag);
        museum.routeTags = newMuseumRouteTags;
        const updatedMuseum = await parseService.updateMuseum(museum);
        if (updatedMuseum) {
          success = true;
          museumsById[museumId] = updatedMuseum;
        }
      }
      this.museums = museumsById;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.isUpdatingMuseums = false;
    return success ? routeTag : null;
  };

  @action
  public updateMuseumRouteTag = async (
    params: Partial<MuseumRouteTag>,
    museumId: Museum["objectId"]
  ) => {
    if (!params.id) {
      return null;
    }
    const routeTag = params as MuseumRouteTag;
    this.isUpdatingMuseums = true;
    let success = false;
    try {
      const museums = await parseService.fetchMuseums();
      const museumsById = _.keyBy(museums, "objectId");
      const museum = museumsById[museumId];
      if (museum) {
        const museumRouteTags = _.get(museum, "routeTags") || [];
        const newMuseumRouteTags = museumRouteTags.map(tag =>
          tag.id === routeTag.id ? { ...tag, ...routeTag } : tag
        );
        museum.routeTags = newMuseumRouteTags;
        const updatedMuseum = await parseService.updateMuseum(museum);
        if (updatedMuseum) {
          success = true;
          museumsById[museumId] = updatedMuseum;
        }
      }
      this.museums = museumsById;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.isUpdatingMuseums = false;
    return success ? routeTag : null;
  };

  @action
  public deleteMuseumRouteTag = async (
    id: MuseumRouteTag["id"],
    museumId: Museum["objectId"]
  ) => {
    this.isUpdatingMuseums = true;
    let success = false;
    try {
      const museums = await parseService.fetchMuseums();
      const museumsById = _.keyBy(museums, "objectId");
      const museum = museumsById[museumId];
      if (museum) {
        const museumRouteTags = _.get(museum, "routeTags") || [];
        const newMuseumRouteTags = museumRouteTags.filter(tag => tag.id !== id);
        museum.routeTags = newMuseumRouteTags;
        const updatedMuseum = await parseService.updateMuseum(museum);
        if (updatedMuseum) {
          success = true;
          museumsById[museumId] = updatedMuseum;
        }
      }
      this.museums = museumsById;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.isUpdatingMuseums = false;
    return success;
  };

  @computed
  get selectedMuseum(): Museum | null {
    if (!this.selectedMuseumId || !this.museums) {
      return null;
    }
    return this.museums[this.selectedMuseumId] || null;
  }
}
