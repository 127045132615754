import _ from "lodash";

const pickOrUndefined = (obj: any, props: string[]) => {
  const result: any = {};
  props.forEach(prop => (result[prop] = undefined));
  return _.merge(result, _.pick(obj, props));
};

export default {
  pickOrUndefined
};
