import { MutableRefObject, useEffect, useState } from "react";

let isIntersectionObserverAvailable = true;
if (
  !("IntersectionObserver" in window) ||
  !("IntersectionObserverEntry" in window) ||
  // @ts-ignore
  !("intersectionRatio" in window.IntersectionObserverEntry.prototype)
) {
  isIntersectionObserverAvailable = false; // Or load polyfill
}

const useOnScreen = (
  ref: MutableRefObject<HTMLElement | null>,
  rootMargin: string = "0px"
) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!isIntersectionObserverAvailable) {
      setIntersecting(true);
      return;
    }
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin
      }
    );
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      currentRef && observer.unobserve(currentRef);
    };
  }, [ref, rootMargin]);

  return isIntersecting;
};

export default useOnScreen;
