import React, { useCallback, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import uiUtils from "../utils/ui";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Layout from "../components/Layout";
import Link from "../components/Link";
import PageHeader from "../components/PageHeader";
import Popconfirm from "../components/Popconfirm";
import Spinner from "../components/Spinner";
import Table from "../components/Table";
import { Stores } from "../types/stores";
import { Artwork } from "../types/artwork";

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  tenantId: string | null;
  museumId: string | null;
  fetchArtworks: () => Promise<void>;
  deleteArtwork: (artworkId: Artwork["objectId"]) => Promise<boolean>;
  artworks: Artwork[];
  isLoading: boolean;
  canCreateArtworks: boolean;
  canDeleteArtworks: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const museumId = navigationUtils.fromRoutes.museumId(
    ownProps.location.pathname
  );
  const currentUserId = _.get(stores.auth.user, "objectId");
  const canCreateArtworks =
    stores.users.getIsAdmin(currentUserId) ||
    stores.users.getIsTenantAdmin(currentUserId, tenantId) ||
    stores.users.getIsMuseumAdmin(currentUserId, museumId) ||
    stores.users.getIsContentAdmin(currentUserId, museumId);
  const canDeleteArtworks = canCreateArtworks;
  return {
    tenantId,
    museumId,
    fetchArtworks: stores.artworks.fetchArtworks,
    deleteArtwork: stores.artworks.deleteArtwork,
    artworks: _.values(stores.artworks.artworks).filter(
      artwork => artwork.museum.objectId === museumId
    ),
    isLoading: stores.artworks.isFetchingArtworks,
    canCreateArtworks,
    canDeleteArtworks
  };
};

const ArtworkListScreen = ({
  tenantId,
  museumId,
  fetchArtworks,
  deleteArtwork,
  artworks,
  isLoading,
  canCreateArtworks,
  canDeleteArtworks
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchArtworks();
  }, [fetchArtworks]);

  const handleDeleteArtwork = useCallback(
    (artworkId: string) => {
      deleteArtwork(artworkId).then(success => {
        if (success) {
          loggerService.success("Eliminazione avvenuta con successo");
        } else {
          loggerService.success("Eliminazione non riuscita");
        }
      });
    },
    [deleteArtwork]
  );

  const renderItemActionButtons = useCallback(
    (objectId: string) => {
      return (
        <div style={{ textAlign: "right" }}>
          {canDeleteArtworks && (
            <Popconfirm
              placement="leftTop"
              title={"Sei sicuro di eliminarlo?"}
              onConfirm={() => handleDeleteArtwork(objectId)}
              okText="Procedi"
              cancelText="Torna indietro"
            >
              <Button>
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          )}
        </div>
      );
    },
    [handleDeleteArtwork, canDeleteArtworks]
  );

  if (isLoading || !tenantId || !museumId) {
    return <Spinner />;
  }

  return (
    <Layout>
      <PageHeader
        title="Lista contenuti"
        extra={
          canCreateArtworks && (
            <Link
              to={navigationUtils.routes.artwork.create(tenantId, museumId)}
            >
              <Button>{"Crea nuovo punto di interesse"}</Button>
            </Link>
          )
        }
      />
      <Table dataSource={artworks.map(a => ({ ...a, key: a.objectId }))}>
        <Table.Column
          title="Nome"
          dataIndex="name.it"
          key="name"
          sorter={uiUtils.getSortFunction<Artwork>("name.it")}
          render={(name, artwork) => (
            <Link
              to={navigationUtils.routes.artwork.find(
                tenantId,
                museumId,
                artwork.objectId
              )}
            >
              {name}
            </Link>
          )}
        />
        <Table.Column
          title="Creato il"
          dataIndex="createdAt"
          key="createdAt"
          sorter={uiUtils.getSortFunction<Artwork>("createdAt")}
          render={uiUtils.prettifyDateTime}
        />
        <Table.Column
          title="Aggiornato al"
          dataIndex="updatedAt"
          key="updatedAt"
          sorter={uiUtils.getSortFunction<Artwork>("updatedAt")}
          render={uiUtils.prettifyDateTime}
        />
        <Table.Column
          dataIndex="objectId"
          key="objectId"
          render={renderItemActionButtons}
        />
      </Table>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(ArtworkListScreen));
