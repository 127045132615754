import { action, computed, observable } from "mobx";
import { isNil } from "lodash";

import loggerService from "../services/logger";
import parseService from "../services/parse";
import { User } from "../types/user";

export default class AuthStore {
  @observable
  public isInitialized: boolean = false;
  @observable
  public isLoggingIn: boolean = false;
  @observable
  public isLoggingOut: boolean = false;
  @observable
  public user: User | null = null;
  @observable
  public isSuperAdmin: boolean = false;

  @action
  public initialize = async () => {
    await this.fetchCurrentUser();
    this.isInitialized = true;
  };

  @action
  public fetchCurrentUser = async () => {
    let user: User | null = null;
    try {
      user = parseService.getCurrentUser();
    } catch (err) {
      loggerService.warning(err.message);
    }
    this.user = user;
  };

  @action
  public login = async (email: string, password: string): Promise<boolean> => {
    this.isLoggingIn = true;
    let user: User | null = null;
    try {
      user = await parseService.login(email, password);
    } catch (err) {
      loggerService.warning(err.message);
    }
    this.user = user;
    this.isSuperAdmin = user ? user.email === "root@themostaza.com" : false;
    this.isLoggingIn = false;
    return !isNil(user);
  };

  @action
  public logout = async (): Promise<void> => {
    this.isLoggingOut = true;
    let user: User | null = null;
    try {
      user = await parseService.logout();
    } catch (err) {
      loggerService.warning(err.message);
    }
    this.user = user;
    this.isSuperAdmin = false;
    this.isLoggingOut = false;
  };

  @action
  public destroy = async (): Promise<void> => {
    await this.logout();
  };

  @computed
  get isLoggedIn() {
    return !isNil(this.user);
  }
}

export const authStore = new AuthStore();
