import React from "react";

type Props = {
  src?: any;
  controls?: boolean;
};

const Video = ({ src, controls = true }: Props) => {
  return (
    <video
      src={src}
      controls={controls}
      style={{ width: "100%", height: "100%", objectFit: "contain" }}
    />
  );
};

export default Video;
