import React from "react";
import _ from "lodash";

import Button from "../Button";
import Icon from "../Icon";
import TimePicker from "../TimePicker";
import Styled from "./styled";
import { ExcludeProps } from "../../utils/typescript";
import { TimestampMilliseconds } from "../../types/timestamp";

interface TimeSchedule {
  open?: TimestampMilliseconds;
  close?: TimestampMilliseconds;
}

const emptyTimeSchedule: TimeSchedule = {
  open: undefined,
  close: undefined
};

type AntdTimePickerFilteredProps = ExcludeProps<
  React.ComponentProps<typeof TimePicker>,
  "value" | "onChange"
>;

type Props = AntdTimePickerFilteredProps & {
  value?: TimeSchedule[];
  onChange?: (value: TimeSchedule[]) => any;
};

export default React.forwardRef(
  ({ value, onChange, ...restProps }: Props, ref: React.Ref<any>) => {
    const timeSchedules: TimeSchedule[] =
      _.isArray(value) && value.length > 0 ? value : [emptyTimeSchedule];
    const handleChange = (index: number, timeSchedule: TimeSchedule) => {
      const newTimeSchedules = timeSchedules.slice();
      newTimeSchedules[index] = timeSchedule;
      onChange && onChange(newTimeSchedules);
    };
    const handleDelete = (index: number) => {
      const newTimeSchedules = timeSchedules.slice();
      newTimeSchedules.splice(index, 1);
      if (newTimeSchedules.length === 0) {
        newTimeSchedules.push(emptyTimeSchedule);
      }
      onChange && onChange(newTimeSchedules);
    };
    const handleAdd = () => {
      const newTimeSchedules = timeSchedules.slice();
      newTimeSchedules.push(emptyTimeSchedule);
      onChange && onChange(newTimeSchedules);
    };
    return (
      <Styled.ScheduleTable ref={ref}>
        <thead>
          <tr>
            <th>Apertura</th>
            <th>Chiusura</th>
            <th>
              <Button onClick={handleAdd}>
                <Icon type="plus" />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {timeSchedules.map((timeSchedule, index) => (
            <tr key={`${index}`}>
              <td>
                <TimePicker
                  {...restProps}
                  value={timeSchedule.open && timeSchedule.open}
                  onChange={(timestamp?: TimestampMilliseconds) =>
                    handleChange(index, {
                      open: timestamp,
                      close: timeSchedule.close && timeSchedule.close
                    })
                  }
                />
              </td>
              <td>
                <TimePicker
                  {...restProps}
                  value={timeSchedule.close && timeSchedule.close}
                  onChange={(timestamp?: TimestampMilliseconds) =>
                    handleChange(index, {
                      open: timeSchedule.open && timeSchedule.open,
                      close: timestamp
                    })
                  }
                />
              </td>
              <td>
                <Button onClick={() => handleDelete(index)}>
                  <Icon type="delete" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Styled.ScheduleTable>
    );
  }
);
