import { action, observable, computed } from "mobx";
import _ from "lodash";

import loggerService from "../services/logger";
import parseService from "../services/parse";
import AuthStore from "./Auth";

import { Tenant } from "../types/tenant";

export default class TenantsStore {
  @observable
  public isInitialized: boolean = false;
  @observable
  public isFetchingTenants: boolean = false;
  @observable
  public isCreatingTenants: boolean = false;
  @observable
  public isUpdatingTenants: boolean = false;
  @observable
  public isDeletingTenants: boolean = false;
  @observable
  public tenants: { [tenantId: string]: Tenant } | null = null;
  @observable
  public selectedTenantId: Tenant["objectId"] | null = null;
  public authStore: AuthStore;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

  @action
  public initialize = async () => {
    this.isInitialized = true;
  };

  @action
  public fetchTenants = async () => {
    let tenants: Tenant[] = [];
    this.isFetchingTenants = true;
    try {
      tenants = await parseService.fetchTenants();
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.tenants = _.keyBy(tenants, "objectId");
    this.isFetchingTenants = false;
  };

  @action
  public createTenant = async (
    params: Partial<Tenant>
  ): Promise<Tenant | null> => {
    this.isCreatingTenants = true;
    let createdTenant: Tenant | null = null;
    try {
      createdTenant = await parseService.createTenant(params);
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.tenants) {
      this.tenants = {};
    }
    if (createdTenant) {
      this.tenants[createdTenant.objectId] = createdTenant;
    }
    this.isCreatingTenants = false;
    return createdTenant;
  };

  @action
  public updateTenant = async (
    params: Partial<Tenant>
  ): Promise<Tenant | null> => {
    if (!params.objectId) {
      return null;
    }
    this.isUpdatingTenants = true;
    let updatedTenant: Tenant | null = null;
    try {
      updatedTenant = await parseService.updateTenant(params);
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.tenants) {
      this.tenants = {};
    }
    if (!updatedTenant) {
      delete this.tenants[params.objectId];
    } else {
      this.tenants[params.objectId] = updatedTenant;
    }
    this.isUpdatingTenants = false;
    return updatedTenant;
  };

  @action
  public deleteTenant = async (tenantId: Tenant["objectId"]) => {
    let success = false;
    this.isDeletingTenants = true;
    try {
      await parseService.deleteTenant(tenantId);
      success = true;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.tenants) {
      this.tenants = {};
    }
    if (success) {
      delete this.tenants[tenantId];
    }
    this.isDeletingTenants = false;
    return success;
  };

  @computed
  get selectedTenant(): Tenant | null {
    if (!this.selectedTenantId || !this.tenants) {
      return null;
    }
    return this.tenants[this.selectedTenantId] || null;
  }
}
