import Parse from "parse";
import _ from "lodash";

import keysConfig from "../config/keys";
import { Artwork } from "../types/artwork";
import { Museum } from "../types/museum";
import { SurveyQuestion } from "../types/survey";
import { MuseumsStats } from "../types/userSession";
import { Tenant } from "../types/tenant";
import { User, Role } from "../types/user";
import { ParseFile } from "../types/parse";

const QUERY_MAX_LIST_SIZE = 1000000;

const initialize = async () => {
  await Parse.initialize(keysConfig.PARSE_APP_ID);
  Parse.serverURL = keysConfig.PARSE_SERVER_URL;
};

const isSessionError = (err: Parse.Error) => {
  // @ts-ignore
  return err != null && err.code === Parse.Error.INVALID_SESSION_TOKEN;
};

const maskObject = <T>(object: Object) => _.omit(object, ["ACL"]) as T;

const getCurrentUser = (): User | null => {
  const currentUser: Parse.User | void = Parse.User.current();
  return currentUser ? maskObject(currentUser.toJSON()) : null;
};

const login = async (
  username: string,
  password: string
): Promise<User | null> => {
  try {
    // Will throw if unsuccessful
    await Parse.User.logIn(username, password);
  } catch (err) {
    console.log(err);
  }
  return getCurrentUser();
};

const logout = async (): Promise<User | null> => {
  await Parse.User.logOut();
  return getCurrentUser();
};

const fetchUsers = async (): Promise<User[]> => {
  const users: Parse.User[] | void = await new Parse.Query(Parse.User)
    .limit(QUERY_MAX_LIST_SIZE)
    .find();
  return users ? users.map(user => maskObject(user.toJSON())) : [];
};

const createUserForTenant = async (
  params: Partial<User>,
  tenantId: Tenant["objectId"]
): Promise<User | null> => {
  const user = new Parse.User();
  const tenant = new Parse.Object("Tenant");
  tenant.id = tenantId;
  user.set("tenant", tenant);
  params.username = params.username || params.email;
  params.email = params.email || params.username;
  const createdUser = await user.save(params);
  return createdUser ? maskObject(createdUser.toJSON()) : null;
};

const updateUser = async (params: Partial<User>): Promise<User | null> => {
  const parseUser: Parse.User | void = await new Parse.Query(Parse.User)
    .equalTo("objectId", params.objectId)
    .first();
  if (!parseUser) {
    return null;
  }
  const updatedUser: Parse.User | void = await parseUser.save(params);
  if (!updatedUser) {
    return null;
  }
  return maskObject(updatedUser.toJSON());
};

const deleteUser = async (userId: string): Promise<void> => {
  const user: Parse.User | void = await new Parse.Query(Parse.User)
    .equalTo("objectId", userId)
    .first();
  if (user) {
    await user.destroy();
  }
};

const fetchRoles = async (): Promise<Role[]> => {
  const foundRoles = [];
  const parseRoles = await new Parse.Query(Parse.Role).find();
  for (const parseRole of parseRoles) {
    const role = parseRole.toJSON();
    const { objectId, name } = role;
    const parseUsers:
      | Parse.User[]
      | void = await parseRole.getUsers().query().find();
    const users = parseUsers ? parseUsers.map(user => user.toJSON()) : [];
    foundRoles.push({ objectId, name, users: _.keyBy(users, "objectId") });
  }
  return foundRoles || [];
};

const addRoleToUser = async (
  userId: string,
  roleName: string
): Promise<boolean> => {
  const [user, role] = await Promise.all([
    new Parse.Query(Parse.User).equalTo("objectId", userId).first(),
    new Parse.Query(Parse.Role).equalTo("name", roleName).first()
  ]);
  if (!user || !role) {
    return false;
  }
  role.getUsers().add(user);
  await role.save();
  return true;
};

const removeRoleToUser = async (
  userId: string,
  roleName: string
): Promise<boolean> => {
  const [user, role] = await Promise.all([
    new Parse.Query(Parse.User).equalTo("objectId", userId).first(),
    new Parse.Query(Parse.Role).equalTo("name", roleName).first()
  ]);
  if (!user || !role) {
    return false;
  }
  role.getUsers().remove(user);
  await role.save();
  return true;
};

const fetchObjects = async <T>(objectClass: string): Promise<Array<T>> => {
  const objects: Parse.Object[] | void = await new Parse.Query(objectClass)
    .limit(QUERY_MAX_LIST_SIZE)
    .find();
  // if (!keysConfig.IS_ENV_PRODUCTION) {
  //   await new Promise(r => setTimeout(r, 2000));
  // }
  return objects ? objects.map(object => maskObject(object.toJSON())) : [];
};

const createObject = async <T>(
  objectClass: string,
  params: Partial<T>
): Promise<T | null> => {
  const object = new Parse.Object(objectClass);
  const createdObject = await object.save(params);
  return createdObject ? maskObject(createdObject.toJSON()) : null;
};

const createObjectWithRef = async <T>(
  objectClass: string,
  refClass: string,
  params: Partial<T>,
  refId: string
): Promise<T | null> => {
  const object = new Parse.Object(objectClass);
  const ref = new Parse.Object(refClass);
  ref.id = refId;
  object.set(refClass.toLowerCase(), ref);
  const createdObject = await object.save(params);
  return createdObject ? maskObject(createdObject.toJSON()) : null;
};

const updateObject = async <T>(
  objectClass: string,
  params: Partial<T>
): Promise<T | null> => {
  // @ts-ignore
  const objectId = params.objectId;
  if (objectId == null) {
    return null;
  }
  const parseObject: Parse.Object | void = await new Parse.Query(objectClass)
    // @ts-ignore
    .equalTo("objectId", objectId)
    .first();
  if (!parseObject) {
    return null;
  }
  const updatedObject: Parse.Object | void = await parseObject.save(params);
  if (!updatedObject) {
    return null;
  }
  return maskObject(updatedObject.toJSON());
};

const deleteObject = async (objectClass: string, objectId: string) => {
  const object: Parse.Object | void = await new Parse.Query(objectClass)
    .equalTo("objectId", objectId)
    .first();
  if (object) {
    await object.destroy();
  }
};

const fetchTenants = () => fetchObjects<Tenant>("Tenant");
const createTenant = async (params: Partial<Tenant>) =>
  createObject<Tenant>("Tenant", params);
const updateTenant = (params: Partial<Tenant>) =>
  updateObject<Tenant>("Tenant", params);
const deleteTenant = (tenantId: Tenant["objectId"]) =>
  deleteObject("Tenant", tenantId);

const fetchMuseums = () => fetchObjects<Museum>("Museum");
const createMuseumForTenant = (
  params: Partial<Museum>,
  tenantId: Tenant["objectId"]
) => createObjectWithRef<Museum>("Museum", "Tenant", params, tenantId);
const updateMuseum = (params: Partial<Museum>) =>
  updateObject<Museum>("Museum", params);
const deleteMuseum = (museumId: Museum["objectId"]) =>
  deleteObject("Museum", museumId);

const fetchArtworks = () => fetchObjects<Artwork>("Artwork");
const createArtworkForMuseum = (
  params: Partial<Artwork>,
  museumId: Museum["objectId"]
) => createObjectWithRef<Artwork>("Artwork", "Museum", params, museumId);
const updateArtwork = (params: Partial<Artwork>) =>
  updateObject<Artwork>("Artwork", params);
const deleteArtwork = (artworkId: Artwork["objectId"]) =>
  deleteObject("Artwork", artworkId);

const fetchSurveyQuestions = () =>
  fetchObjects<SurveyQuestion>("SurveyQuestion");
const createSurveyQuestionForMuseum = (
  params: Partial<SurveyQuestion>,
  museumId: Museum["objectId"]
) =>
  createObjectWithRef<SurveyQuestion>(
    "SurveyQuestion",
    "Museum",
    params,
    museumId
  );
const updateSurveyQuestion = (params: Partial<SurveyQuestion>) =>
  updateObject<SurveyQuestion>("SurveyQuestion", params);
const deleteSurveyQuestion = (surveyQuestionId: SurveyQuestion["objectId"]) =>
  deleteObject("SurveyQuestion", surveyQuestionId);

const fetchStats = async (): Promise<MuseumsStats> => {
  const params = {};
  return await Parse.Cloud.run("getStats", params);
};

const uploadFile = async (
  fileName: string,
  file: any
): Promise<ParseFile | null> => {
  const uploadedFile = await new Parse.File(
    encodeURI(fileName.replace(/'/g, "")),
    file
  ).save();
  // @ts-ignore
  return uploadedFile ? uploadedFile.toJSON() : null;
};

const requestAudioGeneration = async (
  schemaName: "Artwork",
  objectId: Artwork["objectId"],
  languageCode: string
): Promise<boolean> => {
  const successfullyGenerated: boolean = await Parse.Cloud.run(
    "generateAudio",
    {
      schemaName,
      objectId,
      languageCode
    }
  );
  return !!successfullyGenerated;
};

export default {
  initialize,
  isSessionError,
  getCurrentUser,
  login,
  logout,
  fetchUsers,
  createUserForTenant,
  updateUser,
  deleteUser,
  fetchRoles,
  addRoleToUser,
  removeRoleToUser,
  fetchTenants,
  createTenant,
  updateTenant,
  deleteTenant,
  fetchMuseums,
  createMuseumForTenant,
  updateMuseum,
  deleteMuseum,
  fetchArtworks,
  createArtworkForMuseum,
  updateArtwork,
  deleteArtwork,
  fetchSurveyQuestions,
  createSurveyQuestionForMuseum,
  updateSurveyQuestion,
  deleteSurveyQuestion,
  fetchStats,
  uploadFile,
  requestAudioGeneration
};
