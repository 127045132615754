import React, { useCallback, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import uiUtils from "../utils/ui";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Layout from "../components/Layout";
import Link from "../components/Link";
import PageHeader from "../components/PageHeader";
import Popconfirm from "../components/Popconfirm";
import Spinner from "../components/Spinner";
import Table from "../components/Table";
import Tag from "../components/Tag";
import { Stores } from "../types/stores";
import { Museum, MuseumRoute, MuseumRouteTag } from "../types/museum";

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  tenantId: string | null;
  museumId: string | null;
  museum: Museum | null;
  fetchMuseums: () => Promise<void>;
  deleteMuseumRoute: (
    id: MuseumRoute["id"],
    museumId: Museum["objectId"]
  ) => Promise<boolean>;
  deleteMuseumRouteTag: (
    id: MuseumRouteTag["id"],
    museumId: Museum["objectId"]
  ) => Promise<boolean>;
  isLoading: boolean;
  canCreateMuseumRoutes: boolean;
  canDeleteMuseumRoutes: boolean;
  canListMuseumRouteTags: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const museumId = navigationUtils.fromRoutes.museumId(
    ownProps.location.pathname
  );
  const museum = (museumId && _.get(stores.museums.museums, museumId)) || null;
  const currentUserId = _.get(stores.auth.user, "objectId");
  const canCreateMuseumRoutes =
    stores.users.getIsAdmin(currentUserId) ||
    stores.users.getIsTenantAdmin(currentUserId, tenantId) ||
    stores.users.getIsMuseumAdmin(currentUserId, museumId) ||
    stores.users.getIsContentAdmin(currentUserId, museumId);
  const canDeleteMuseumRoutes = canCreateMuseumRoutes;
  const canListMuseumRouteTags = stores.users.getIsAdmin(currentUserId);
  return {
    tenantId,
    museumId,
    museum,
    fetchMuseums: stores.museums.fetchMuseums,
    deleteMuseumRouteTag: stores.museums.deleteMuseumRouteTag,
    deleteMuseumRoute: stores.museums.deleteMuseumRoute,
    isLoading: stores.museums.isFetchingMuseums,
    canCreateMuseumRoutes,
    canDeleteMuseumRoutes,
    canListMuseumRouteTags
  };
};

const MuseumRouteListScreen = ({
  tenantId,
  museumId,
  fetchMuseums,
  deleteMuseumRoute,
  deleteMuseumRouteTag,
  museum,
  isLoading,
  canCreateMuseumRoutes,
  canDeleteMuseumRoutes,
  canListMuseumRouteTags
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchMuseums();
  }, [fetchMuseums]);

  const handleDeleteRoute = useCallback(
    (routeId: string) => {
      if (!museumId) {
        return loggerService.error("Errore durante l'eliminazione");
      }
      deleteMuseumRoute(routeId, museumId).then(() => {
        loggerService.success("Eliminazione avvenuta con successo");
      });
    },
    [deleteMuseumRoute, museumId]
  );

  const handleDeleteRouteTag = useCallback(
    (tagId: string) => {
      if (!museumId) {
        return loggerService.error("Errore durante l'eliminazione");
      }
      deleteMuseumRouteTag(tagId, museumId).then(() => {
        loggerService.success("Eliminazione avvenuta con successo");
      });
    },
    [deleteMuseumRouteTag, museumId]
  );

  const renderRouteActionButtons = useCallback(
    (routeId: string) => {
      return (
        <div style={{ textAlign: "right" }}>
          {canDeleteMuseumRoutes && (
            <Popconfirm
              placement="leftTop"
              title={"Sei sicuro di eliminarlo?"}
              onConfirm={() => handleDeleteRoute(routeId)}
              okText="Procedi"
              cancelText="Torna indietro"
            >
              <Button>
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          )}
        </div>
      );
    },
    [handleDeleteRoute, canDeleteMuseumRoutes]
  );

  const renderRouteTagActionButtons = useCallback(
    (tagId: string) => {
      return (
        <div style={{ textAlign: "right" }}>
          <Popconfirm
            placement="leftTop"
            title={"Sei sicuro di eliminarlo?"}
            onConfirm={() => handleDeleteRouteTag(tagId)}
            okText="Procedi"
            cancelText="Torna indietro"
          >
            <Button>
              <Icon type="delete" />
            </Button>
          </Popconfirm>
        </div>
      );
    },
    [handleDeleteRouteTag]
  );

  if (isLoading || !tenantId || !museumId || !museum) {
    return <Spinner />;
  }

  const tagsById = _.keyBy(museum.routeTags || [], "id");
  return (
    <Layout>
      <PageHeader
        title="Lista itinerari"
        extra={
          canCreateMuseumRoutes && (
            <Link
              to={navigationUtils.routes.museum.route.create(
                tenantId,
                museumId
              )}
            >
              <Button>{"Crea nuovo itinerario"}</Button>
            </Link>
          )
        }
      />
      <Table dataSource={museum.routes.map(r => ({ ...r, key: r.id }))}>
        <Table.Column
          title="Nome"
          dataIndex="name.it"
          key="name"
          sorter={uiUtils.getSortFunction<MuseumRoute>("name.it")}
          render={(name, route) => (
            <Link
              to={navigationUtils.routes.museum.route.find(
                tenantId,
                museumId,
                route.id
              )}
            >
              {name}
            </Link>
          )}
        />
        <Table.Column
          title="Descrizione"
          dataIndex="description.it"
          key="description"
          sorter={uiUtils.getSortFunction<MuseumRoute>("description.it")}
        />
        <Table.Column
          title="Tipologia"
          dataIndex="tag"
          key="tag"
          sorter={uiUtils.getSortFunction<MuseumRoute>(
            museumRoute =>
              museumRoute.tag && _.get(tagsById[museumRoute.tag], "name.it")
          )}
          render={tagId => <Tag>{_.get(tagsById[tagId], "name.it")}</Tag>}
        />
        <Table.Column
          title="№ punti di interesse"
          dataIndex="artworkIds"
          key="artworkIds"
          sorter={uiUtils.getSortFunction<MuseumRoute>("artworkIds.length")}
          render={artworkIds => (artworkIds || []).length}
        />
        <Table.Column
          dataIndex="id"
          key="id"
          render={renderRouteActionButtons}
        />
      </Table>
      <br />
      {canListMuseumRouteTags && (
        <React.Fragment>
          <PageHeader
            title="Lista tipologie itinerari"
            extra={
              <Link
                to={navigationUtils.routes.museum.routeTag.create(
                  tenantId,
                  museumId
                )}
              >
                <Button>{"Crea nuova tipologia"}</Button>
              </Link>
            }
          />
          <Table dataSource={museum.routeTags.map(r => ({ ...r, key: r.id }))}>
            <Table.Column
              title="Nome"
              dataIndex="name.it"
              key="name"
              sorter={uiUtils.getSortFunction<MuseumRoute>("name.it")}
              render={(name, tag) => (
                <Link
                  to={navigationUtils.routes.museum.routeTag.find(
                    tenantId,
                    museumId,
                    tag.id
                  )}
                >
                  <Tag>{name}</Tag>
                </Link>
              )}
            />
            <Table.Column
              title="№ itinerari"
              dataIndex="id"
              key="routes"
              sorter={(tagA: MuseumRouteTag, tagB: MuseumRouteTag) => {
                const routesTagIdA = (museum.routes || []).filter(
                  route => route.tag === tagA.id
                );
                const routesTagIdB = (museum.routes || []).filter(
                  route => route.tag === tagB.id
                );
                return routesTagIdA.length - routesTagIdB.length;
              }}
              render={tagId => {
                const routes = (museum.routes || []).filter(
                  route => route.tag === tagId
                );
                return routes.length;
              }}
            />
            <Table.Column
              dataIndex="id"
              key="id"
              render={renderRouteTagActionButtons}
            />
          </Table>
        </React.Fragment>
      )}
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(MuseumRouteListScreen));
