import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import objectUtils from "../utils/object";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Input from "../components/Input";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Select from "../components/Select";
import Spinner from "../components/Spinner";
import Tabs from "../components/Tabs";
import TransferList from "../components/TransferList";
import Upload from "../components/Upload";
import { Language } from "../types/language";
import { Artwork } from "../types/artwork";
import { Museum, MuseumRoute } from "../types/museum";
import { Stores } from "../types/stores";

const getFormValues = (route: MuseumRoute | null) => {
  return {
    ..._.omit(route, [])
  };
};

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  tenantId: string | null;
  museumId: string | null;
  museum: Museum | null;
  museumRouteId: string | null;
  museumRoute: MuseumRoute | null;
  isCreatingMuseumRoute: boolean;
  languages: Language[];
  artworks: Artwork[];
  createMuseumRoute: (
    params: Partial<MuseumRoute>,
    museumId: Museum["objectId"]
  ) => Promise<MuseumRoute | null>;
  updateMuseumRoute: (
    params: Partial<MuseumRoute>,
    museumId: Museum["objectId"]
  ) => Promise<MuseumRoute | null>;
  fetchArtworks: () => Promise<void>;
  fetchMuseums: () => Promise<void>;
  isLoading: boolean;
  canSaveMuseumRoutes: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const museumId = navigationUtils.fromRoutes.museumId(
    ownProps.location.pathname
  );
  const museumRouteId = navigationUtils.fromRoutes.museumRouteId(
    ownProps.location.pathname
  );
  const museum = (museumId && _.get(stores.museums.museums, museumId)) || null;
  const languages: Language[] = _.get(museum, `languages`, []);
  const museumRoutes = _.get(museum, "routes", []);
  const museumRoute = _.find(museumRoutes, r => r.id === museumRouteId) || null;
  const artworks = _.values(stores.artworks.artworks).filter(
    artwork => artwork.museum.objectId === museumId
  );
  const isCreatingMuseumRoute =
    museumRouteId === navigationUtils.routesUtils.pathCreate;
  const currentUserId = _.get(stores.auth.user, "objectId");
  const canSaveMuseumRoutes =
    stores.users.getIsAdmin(currentUserId) ||
    stores.users.getIsTenantAdmin(currentUserId, tenantId) ||
    stores.users.getIsMuseumAdmin(currentUserId, museumId) ||
    stores.users.getIsContentAdmin(currentUserId, museumId);
  return {
    tenantId,
    museumId,
    museum,
    museumRouteId,
    museumRoute,
    isCreatingMuseumRoute,
    languages,
    artworks,
    createMuseumRoute: stores.museums.createMuseumRoute,
    updateMuseumRoute: stores.museums.updateMuseumRoute,
    fetchArtworks: stores.artworks.fetchArtworks,
    fetchMuseums: stores.museums.fetchMuseums,
    isLoading:
      stores.museums.isFetchingMuseums || stores.artworks.isFetchingArtworks,
    canSaveMuseumRoutes
  };
};

const MuseumRouteDetailScreen = ({
  history,
  location,
  tenantId,
  museumId,
  museum,
  museumRouteId,
  museumRoute,
  isCreatingMuseumRoute,
  languages,
  artworks,
  createMuseumRoute,
  updateMuseumRoute,
  fetchArtworks,
  fetchMuseums,
  isLoading,
  canSaveMuseumRoutes
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchMuseums();
    fetchArtworks();
  }, [fetchMuseums, fetchArtworks]);

  const [formValues, setFormValues] = useState(getFormValues(museumRoute));

  useEffect(() => {
    setFormValues(getFormValues(museumRoute));
  }, [setFormValues, museumRoute]);

  const handleValidSubmit = useCallback(
    (values: any) => {
      if (!canSaveMuseumRoutes) {
        return loggerService.error("Salvataggio non autorizzato");
      }
      if (!tenantId || !museumId) {
        return loggerService.error("Errore nel salvataggio");
      }
      if (!isCreatingMuseumRoute && !museumRoute) {
        return loggerService.error("Errore nel salvataggio");
      }
      const handleSaveMuseumRoute = async () => {
        try {
          const params: any = objectUtils.pickOrUndefined(values, [
            "name",
            "description",
            "tag",
            "coverImage",
            "artworkIds"
          ]);
          if (museumRoute) {
            params.id = museumRoute.id;
          }
          let savedMuseumRoute: MuseumRoute | null = null;
          if (isCreatingMuseumRoute) {
            // @ts-ignore
            savedMuseumRoute = await createMuseumRoute(params, museumId);
          } else {
            savedMuseumRoute = await updateMuseumRoute(params, museumId);
          }
          if (!savedMuseumRoute) {
            return loggerService.error("Errore nel salvataggio");
          }
          loggerService.success("Itinerario salvato");
          navigationUtils.goTo(
            { history, location },
            navigationUtils.routes.museum.route.find(
              tenantId,
              museumId,
              savedMuseumRoute.id
            )
          );
        } catch (err) {
          loggerService.error("Errore nel salvataggio");
        }
      };
      return handleSaveMuseumRoute();
    },
    [
      canSaveMuseumRoutes,
      isCreatingMuseumRoute,
      museumRoute,
      createMuseumRoute,
      updateMuseumRoute,
      museumId,
      tenantId,
      history,
      location
    ]
  );

  const [selectedLanguage, setSelectedLanguage] = useState("it");

  if (
    isLoading ||
    !tenantId ||
    !museumId ||
    !museum ||
    !museumRouteId ||
    (!museumRoute && !isCreatingMuseumRoute)
  ) {
    return <Spinner />;
  }

  const firstAvailableLanguage: Language | undefined = _.get(
    languages,
    "0",
    undefined
  );
  const pageTitle = isCreatingMuseumRoute
    ? "Crea nuovo itinerario"
    : _.get(museumRoute, `name.it`) ||
      (firstAvailableLanguage &&
        _.get(museumRoute, `name.${firstAvailableLanguage}`)) ||
      "Modifica itinerario";

  return (
    <Layout style={{ height: "100%" }}>
      <PageHeader title={pageTitle} />
      <Form
        key={`${formValues.id}`}
        initialValues={formValues}
        onSubmit={handleValidSubmit}
        hasSubmitButton={canSaveMuseumRoutes}
      >
        <Tabs defaultActiveKey="it" onChange={setSelectedLanguage}>
          {languages.map(language => (
            <Tabs.TabPane tab={language} key={language} />
          ))}
        </Tabs>
        <FormField
          name={"name"}
          label="Nome"
          as={Input}
          type="text"
          required
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={"description"}
          label="Descrizione"
          as={Input}
          type="textarea"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name="coverImage"
          label="Immagine di copertina"
          as={Upload}
          mimeTypes={["image/jpeg", "image/png"]}
          filesMaxCount={1}
        />
        <FormField
          name="tag"
          label="Tipologia"
          as={Select}
          // @ts-ignore
          options={museum.routeTags
            .map(tag => ({
              value: tag.id,
              title: tag.name.it
            }))
            .concat({
              value: undefined,
              title: "-"
            })}
        />
        <FormField
          name={"artworkIds"}
          label="Punti di interesse"
          as={TransferList}
          options={artworks}
          getOptionId={(artwork: Artwork) => _.get(artwork, "objectId")}
          getOptionContent={(artwork: Artwork) => _.get(artwork, "name.it")}
        />
      </Form>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(MuseumRouteDetailScreen));
