import React, { useCallback, useState } from "react";
import { inject, observer } from "mobx-react";
import { Location } from "history";
import { Redirect } from "react-router-dom";

import assetsConfig from "../config/assets";
import loggerService from "../services/logger";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Image from "../components/Image";
import Input from "../components/Input";
import Layout from "../components/Layout";
import { Stores } from "../types/stores";

interface OwnProps {
  location: Location;
}

interface StoresProps {
  isLoading: boolean;
  login: (email: string, password: string) => Promise<boolean>;
}

const mapStoresToProps = (stores: Stores): StoresProps => {
  return {
    isLoading: stores.auth.isLoggingIn,
    login: stores.auth.login
  };
};

const LoginScreen = ({
  isLoading,
  login,
  location
}: OwnProps & StoresProps) => {
  const [shouldRedirectToReferrer, setShouldRedirectToReferrer] = useState(
    false
  );
  const handleLoginClick = useCallback(
    ({ username, password }) => {
      login(username, password).then(isAuthenticated => {
        if (isAuthenticated) {
          setShouldRedirectToReferrer(true);
        } else {
          loggerService.error("Login failed");
        }
      });
    },
    [login]
  );

  if (shouldRedirectToReferrer) {
    const { from } = location.state || { from: { pathname: "/" } };
    return <Redirect to={from} />;
  }

  const formValues = {
    // username: "root@themostaza.com",
    // password: "Mostaza1"
  };

  return (
    <Layout
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center"
      }}
    >
      <Image
        src={assetsConfig.images.banner}
        alt={"Amuse banner and logo"}
        style={{ maxHeight: "20vh", marginBottom: "10vh" }}
      />
      <Form
        initialValues={formValues}
        submitButtonLabel={"Login"}
        onSubmit={handleLoginClick}
      >
        <FormField name="username" label="Email" as={Input} type={"text"} />
        <FormField
          name="password"
          label="Password"
          as={Input}
          type={"password"}
        />
      </Form>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(LoginScreen));
