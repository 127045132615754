import React, { useCallback, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import uiUtils from "../utils/ui";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Layout from "../components/Layout";
import Link from "../components/Link";
import PageHeader from "../components/PageHeader";
import Popconfirm from "../components/Popconfirm";
import Spinner from "../components/Spinner";
import Table from "../components/Table";
import { Stores } from "../types/stores";
import { Museum } from "../types/museum";

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  fetchMuseums: () => Promise<void>;
  deleteMuseum: (museumId: Museum["objectId"]) => Promise<boolean>;
  museums: Museum[];
  isLoading: boolean;
  canDeleteMuseums: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const currentUserId = _.get(stores.auth.user, "objectId");
  const canDeleteMuseums = stores.users.getIsAdmin(currentUserId);
  return {
    fetchMuseums: stores.museums.fetchMuseums,
    deleteMuseum: stores.museums.deleteMuseum,
    museums: _.values(stores.museums.museums),
    isLoading: stores.museums.isFetchingMuseums,
    canDeleteMuseums
  };
};

const MuseumListScreen = ({
  fetchMuseums,
  deleteMuseum,
  museums,
  isLoading,
  canDeleteMuseums
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchMuseums();
  }, [fetchMuseums]);

  const handleDeleteMuseum = useCallback(
    (museumId: string) => {
      deleteMuseum(museumId).then(success => {
        if (success) {
          loggerService.success("Eliminazione avvenuta con successo");
        } else {
          loggerService.success("Eliminazione non riuscita");
        }
      });
    },
    [deleteMuseum]
  );

  const renderItemActionButtons = useCallback(
    (objectId: string) => {
      return (
        <div style={{ textAlign: "right" }}>
          {canDeleteMuseums && (
            <Popconfirm
              placement="leftTop"
              title={"Sei sicuro di eliminarlo?"}
              onConfirm={() => handleDeleteMuseum(objectId)}
              okText="Procedi"
              cancelText="Torna indietro"
            >
              <Button>
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          )}
        </div>
      );
    },
    [handleDeleteMuseum, canDeleteMuseums]
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Layout>
      <PageHeader title="Lista musei" />
      <Table dataSource={museums.map(m => ({ ...m, key: m.objectId }))}>
        <Table.Column
          title="Nome"
          dataIndex="name.it"
          key="name"
          sorter={uiUtils.getSortFunction<Museum>("name.it")}
          render={(name, museum) => (
            <Link
              to={navigationUtils.routes.museum.find(
                museum.tenant.objectId,
                museum.objectId
              )}
            >
              {name}
            </Link>
          )}
        />
        <Table.Column
          title="Cliente"
          dataIndex="tenant"
          key="tenant"
          sorter={uiUtils.getSortFunction<Museum>("tenant")}
          render={tenant => (tenant ? tenant.objectId : "")}
        />
        <Table.Column
          title="Creato il"
          dataIndex="createdAt"
          key="createdAt"
          sorter={uiUtils.getSortFunction<Museum>("createdAt")}
          render={uiUtils.prettifyDateTime}
        />
        <Table.Column
          title="Aggiornato al"
          dataIndex="updatedAt"
          key="updatedAt"
          sorter={uiUtils.getSortFunction<Museum>("updatedAt")}
          render={uiUtils.prettifyDateTime}
        />
        <Table.Column
          dataIndex="objectId"
          key="objectId"
          render={renderItemActionButtons}
        />
      </Table>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(MuseumListScreen));
