import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import objectUtils from "../utils/object";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Input from "../components/Input";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Spinner from "../components/Spinner";
import Tabs from "../components/Tabs";
import { Language } from "../types/language";
import { Museum, MuseumRouteTag } from "../types/museum";
import { Stores } from "../types/stores";

const getFormValues = (routeTag: MuseumRouteTag | null) => {
  return {
    ..._.omit(routeTag, [])
  };
};

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  tenantId: string | null;
  museumId: string | null;
  museumRouteTagId: string | null;
  museumRouteTag: MuseumRouteTag | null;
  isCreatingMuseumRouteTag: boolean;
  createMuseumRouteTag: (
    params: Partial<MuseumRouteTag>,
    museumId: Museum["objectId"]
  ) => Promise<MuseumRouteTag | null>;
  updateMuseumRouteTag: (
    params: Partial<MuseumRouteTag>,
    museumId: Museum["objectId"]
  ) => Promise<MuseumRouteTag | null>;
  languages: Language[];
  fetchArtworks: () => Promise<void>;
  fetchMuseums: () => Promise<void>;
  isLoading: boolean;
  canSaveMuseumRouteTags: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const museumId = navigationUtils.fromRoutes.museumId(
    ownProps.location.pathname
  );
  const museumRouteTagId = navigationUtils.fromRoutes.museumRouteTagId(
    ownProps.location.pathname
  );
  const museum = (museumId && _.get(stores.museums.museums, museumId)) || null;
  const museumRouteTags = _.get(museum, "routeTags", []);
  const museumRouteTag =
    _.find(museumRouteTags, r => r.id === museumRouteTagId) || null;
  const isCreatingMuseumRouteTag =
    museumRouteTagId === navigationUtils.routesUtils.pathCreate;
  const languages: Language[] = _.get(museum, `languages`, []);
  const currentUserId = _.get(stores.auth.user, "objectId");
  const canSaveMuseumRouteTags = stores.users.getIsAdmin(currentUserId);

  return {
    tenantId,
    museumId,
    museumRouteTagId,
    museumRouteTag,
    isCreatingMuseumRouteTag,
    createMuseumRouteTag: stores.museums.createMuseumRouteTag,
    updateMuseumRouteTag: stores.museums.updateMuseumRouteTag,
    languages,
    fetchArtworks: stores.artworks.fetchArtworks,
    fetchMuseums: stores.museums.fetchMuseums,
    isLoading:
      stores.museums.isFetchingMuseums || stores.artworks.isFetchingArtworks,
    canSaveMuseumRouteTags
  };
};

const MuseumRouteDetailScreen = ({
  history,
  location,
  tenantId,
  museumId,
  museumRouteTagId,
  museumRouteTag,
  isCreatingMuseumRouteTag,
  createMuseumRouteTag,
  updateMuseumRouteTag,
  languages,
  fetchArtworks,
  fetchMuseums,
  isLoading,
  canSaveMuseumRouteTags
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchMuseums();
    fetchArtworks();
  }, [fetchMuseums, fetchArtworks]);

  const [formValues, setFormValues] = useState(getFormValues(museumRouteTag));

  useEffect(() => {
    setFormValues(getFormValues(museumRouteTag));
  }, [setFormValues, museumRouteTag]);

  const handleValidSubmit = useCallback(
    (values: any) => {
      if (!canSaveMuseumRouteTags) {
        return loggerService.error("Salvataggio non autorizzato");
      }
      if (!tenantId || !museumId) {
        return loggerService.error("Errore nel salvataggio");
      }
      if (!isCreatingMuseumRouteTag && !museumRouteTag) {
        return loggerService.error("Errore nel salvataggio");
      }
      const handleSaveMuseumRouteTag = async () => {
        try {
          const params: any = objectUtils.pickOrUndefined(values, ["name"]);
          if (museumRouteTag) {
            params.id = museumRouteTag.id;
          }
          let savedMuseumRouteTag: MuseumRouteTag | null = null;
          if (isCreatingMuseumRouteTag) {
            // @ts-ignore
            savedMuseumRouteTag = await createMuseumRouteTag(params, museumId);
          } else {
            savedMuseumRouteTag = await updateMuseumRouteTag(params, museumId);
          }
          if (!savedMuseumRouteTag) {
            return loggerService.error("Errore nel salvataggio");
          }
          loggerService.success("Tipologia itinerario salvata");
          navigationUtils.goTo(
            { history, location },
            navigationUtils.routes.museum.routeTag.find(
              tenantId,
              museumId,
              savedMuseumRouteTag.id
            )
          );
        } catch (err) {
          loggerService.error("Errore nel salvataggio");
        }
      };
      return handleSaveMuseumRouteTag();
    },
    [
      canSaveMuseumRouteTags,
      isCreatingMuseumRouteTag,
      museumRouteTag,
      createMuseumRouteTag,
      updateMuseumRouteTag,
      museumId,
      tenantId,
      history,
      location
    ]
  );

  const [selectedLanguage, setSelectedLanguage] = useState("it");

  if (
    isLoading ||
    !tenantId ||
    !museumId ||
    !museumRouteTagId ||
    (!museumRouteTag && !isCreatingMuseumRouteTag)
  ) {
    return <Spinner />;
  }

  const firstAvailableLanguage: Language | undefined = _.get(
    languages,
    "0",
    undefined
  );
  const pageTitle = isCreatingMuseumRouteTag
    ? "Crea nuova tipologia per itinerari"
    : _.get(museumRouteTag, `name.it`) ||
      (firstAvailableLanguage &&
        _.get(museumRouteTag, `name.${firstAvailableLanguage}`)) ||
      "Modifica tipologia per itinerari";

  return (
    <Layout style={{ height: "100%" }}>
      <PageHeader title={pageTitle} />
      <Form
        key={`${formValues.id}`}
        initialValues={formValues}
        onSubmit={handleValidSubmit}
        hasSubmitButton={canSaveMuseumRouteTags}
      >
        <Tabs defaultActiveKey="it" onChange={setSelectedLanguage}>
          {languages.map(language => (
            <Tabs.TabPane tab={language} key={language} />
          ))}
        </Tabs>
        <FormField
          name={"name"}
          label="Nome"
          as={Input}
          type="text"
          required
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
      </Form>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(MuseumRouteDetailScreen));
