import { action, observable, computed } from "mobx";
import _ from "lodash";

import loggerService from "../services/logger";
import parseService from "../services/parse";
import AuthStore from "./Auth";

import { SurveyQuestion } from "../types/survey";
import { MuseumsStats } from "../types/userSession";
import { Museum } from "../types/museum";

export default class SurveysStore {
  @observable
  public isInitialized: boolean = false;
  @observable
  public isFetchingSurveyQuestions: boolean = false;
  @observable
  public isCreatingSurveyQuestions: boolean = false;
  @observable
  public isUpdatingSurveyQuestions: boolean = false;
  @observable
  public isDeletingSurveyQuestions: boolean = false;
  @observable
  public surveyQuestions: {
    [surveyQuestionId: string]: SurveyQuestion;
  } | null = null;
  @observable
  public stats: MuseumsStats | null = null;

  @observable
  public isFetchingStats: boolean = false;
  @observable
  public rangedArtworks: {
    [artworkId: string]: number;
  } | null = null;
  @observable
  public visitedArtworks: {
    [artworkId: string]: number;
  } | null = null;
  @observable
  public listenedArtworks: {
    [artworkId: string]: number;
  } | null = null;

  @observable
  public selectedSurveyQuestionId: SurveyQuestion["objectId"] | null = null;
  public authStore: AuthStore;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

  @action
  public initialize = async () => {
    this.isInitialized = true;
  };

  @action
  public fetchSurveyQuestions = async () => {
    let surveyQuestions: SurveyQuestion[] = [];
    this.isFetchingSurveyQuestions = true;
    try {
      surveyQuestions = await parseService.fetchSurveyQuestions();
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.surveyQuestions = _.keyBy(surveyQuestions, "objectId");
    this.isFetchingSurveyQuestions = false;
  };

  @action
  public createSurveyQuestion = async (
    params: Partial<SurveyQuestion>,
    museumId: Museum["objectId"]
  ): Promise<SurveyQuestion | null> => {
    this.isCreatingSurveyQuestions = true;
    let createdSurveyQuestion: SurveyQuestion | null = null;
    try {
      createdSurveyQuestion = await parseService.createSurveyQuestionForMuseum(
        params,
        museumId
      );
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.surveyQuestions) {
      this.surveyQuestions = {};
    }
    if (createdSurveyQuestion) {
      this.surveyQuestions[
        createdSurveyQuestion.objectId
      ] = createdSurveyQuestion;
    }
    this.isCreatingSurveyQuestions = false;
    return createdSurveyQuestion;
  };

  @action
  public updateSurveyQuestion = async (
    params: Partial<SurveyQuestion>
  ): Promise<SurveyQuestion | null> => {
    if (!params.objectId) {
      return null;
    }
    this.isUpdatingSurveyQuestions = true;
    let updatedSurveyQuestion: SurveyQuestion | null = null;
    try {
      updatedSurveyQuestion = await parseService.updateSurveyQuestion(params);
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.surveyQuestions) {
      this.surveyQuestions = {};
    }
    if (!updatedSurveyQuestion) {
      delete this.surveyQuestions[params.objectId];
    } else {
      this.surveyQuestions[params.objectId] = updatedSurveyQuestion;
    }
    this.isUpdatingSurveyQuestions = false;
    return updatedSurveyQuestion;
  };

  @action
  public deleteSurveyQuestion = async (
    surveyQuestionId: SurveyQuestion["objectId"]
  ) => {
    let success = false;
    this.isDeletingSurveyQuestions = true;
    try {
      await parseService.deleteSurveyQuestion(surveyQuestionId);
      success = true;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.surveyQuestions) {
      this.surveyQuestions = {};
    }
    if (success) {
      delete this.surveyQuestions[surveyQuestionId];
    }
    this.isDeletingSurveyQuestions = false;
    return success;
  };

  @action
  public fetchStats = async () => {
    let stats: MuseumsStats = {};
    this.isFetchingStats = true;
    try {
      stats = await parseService.fetchStats();
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.stats = stats;
    this.isFetchingStats = false;
  };

  @computed
  get selectedSurveyQuestion(): SurveyQuestion | null {
    if (!this.selectedSurveyQuestionId || !this.surveyQuestions) {
      return null;
    }
    return this.surveyQuestions[this.selectedSurveyQuestionId] || null;
  }
}
