import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import objectUtils from "../utils/object";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Input from "../components/Input";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Spinner from "../components/Spinner";
import { Stores } from "../types/stores";
import { Tenant } from "../types/tenant";

const getFormValues = (tenant: Tenant | null) => {
  return _.omit(tenant, []);
};

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  tenantId: string | null;
  tenant: Tenant | null;
  fetchTenants: () => Promise<void>;
  createTenant: (params: Partial<Tenant>) => Promise<Tenant | null>;
  updateTenant: (params: Partial<Tenant>) => Promise<Tenant | null>;
  isCreatingTenant: boolean;
  isLoading: boolean;
  canSaveTenant: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const tenant = (tenantId && _.get(stores.tenants.tenants, tenantId)) || null;
  const isCreatingTenant = tenantId === navigationUtils.routesUtils.pathCreate;
  const currentUserId = _.get(stores.auth.user, "objectId");
  const canSaveTenant = stores.users.getIsAdmin(currentUserId);
  return {
    tenantId,
    tenant,
    fetchTenants: stores.tenants.fetchTenants,
    createTenant: stores.tenants.createTenant,
    updateTenant: stores.tenants.updateTenant,
    isCreatingTenant,
    isLoading: stores.tenants.isFetchingTenants,
    canSaveTenant
  };
};

const TenantDetailScreen = ({
  history,
  location,
  tenantId,
  tenant,
  fetchTenants,
  createTenant,
  updateTenant,
  isCreatingTenant,
  isLoading,
  canSaveTenant
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchTenants();
  }, [fetchTenants]);

  const [formValues, setFormValues] = useState(getFormValues(tenant));

  useEffect(() => {
    setFormValues(getFormValues(tenant));
  }, [setFormValues, tenant]);

  const handleValidSubmit = useCallback(
    (values: any) => {
      if (!canSaveTenant) {
        return loggerService.error("Salvataggio non autorizzato");
      }
      if (!tenantId) {
        return loggerService.error("Errore nel salvataggio");
      }
      if (!isCreatingTenant && !tenant) {
        return loggerService.error("Errore nel salvataggio");
      }
      const handleSaveTenant = async () => {
        try {
          const params: any = objectUtils.pickOrUndefined(values, [
            "name",
            "credits"
          ]);
          if (tenant) {
            params.objectId = tenant.objectId;
          }
          let savedTenant: Tenant | null = null;
          if (isCreatingTenant) {
            // @ts-ignore
            savedTenant = await createTenant(params, tenantId);
          } else {
            savedTenant = await updateTenant(params);
          }
          if (!savedTenant) {
            return loggerService.error("Errore nel salvataggio");
          }
          loggerService.success("Salvataggio riuscito");
          navigationUtils.goTo(
            { history, location },
            navigationUtils.routes.tenant.find(tenantId)
          );
        } catch (err) {
          loggerService.error("Errore nel salvataggio");
        }
      };
      return handleSaveTenant();
    },
    [
      canSaveTenant,
      isCreatingTenant,
      tenant,
      createTenant,
      updateTenant,
      tenantId,
      history,
      location
    ]
  );

  if (isLoading || !tenantId || (!tenant && !isCreatingTenant)) {
    return <Spinner />;
  }

  const pageTitle = _.get(tenant, `name`) || "Modifica cliente";

  return (
    <Layout style={{ height: "100%" }}>
      <PageHeader title={isCreatingTenant ? "Crea nuovo cliente" : pageTitle} />
      <Form
        key={formValues.objectId}
        initialValues={formValues}
        onSubmit={handleValidSubmit}
        hasSubmitButton={canSaveTenant}
      >
        <FormField name="name" label="Nome" as={Input} type="text" required />
        <FormField
          name="credits"
          label="Crediti"
          as={Input}
          type="number"
          required
        />
      </Form>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(TenantDetailScreen));
