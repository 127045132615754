import { action, observable, computed } from "mobx";
import _ from "lodash";

import loggerService from "../services/logger";
import parseService from "../services/parse";
import AuthStore from "./Auth";

import { User, Role } from "../types/user";
import { Tenant } from "../types/tenant";

export default class UsersStore {
  @observable
  public isInitialized: boolean = false;
  @observable
  public isFetchingUsers: boolean = false;
  @observable
  public isCreatingUsers: boolean = false;
  @observable
  public isUpdatingUsers: boolean = false;
  @observable
  public isDeletingUsers: boolean = false;
  @observable
  public users: { [userId: string]: User } | null = null;
  @observable
  public roles: { [roleName: string]: Role } | null = null;
  @observable
  public selectedUserId: User["objectId"] | null = null;
  public authStore: AuthStore;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

  @action
  public initialize = async () => {
    this.isInitialized = true;
  };

  @action
  public fetchUsers = async () => {
    let users: User[] = [];
    let roles: Role[] = [];
    this.isFetchingUsers = true;
    try {
      [users, roles] = await Promise.all([
        parseService.fetchUsers(),
        parseService.fetchRoles()
      ]);
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.users = _.keyBy(users, "objectId");
    this.roles = _.keyBy(roles, "name");
    this.isFetchingUsers = false;
  };

  @action
  public createUser = async (
    params: Partial<User>,
    tenantId: Tenant["objectId"]
  ): Promise<User | null> => {
    this.isCreatingUsers = true;
    let createdUser: User | null = null;
    try {
      createdUser = await parseService.createUserForTenant(params, tenantId);
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (createdUser) {
      if (!this.users) {
        this.users = {};
      }
      this.users[createdUser.objectId] = createdUser;
    }
    this.isCreatingUsers = false;
    return createdUser;
  };

  @action
  public updateUser = async (params: Partial<User>): Promise<User | null> => {
    if (!params.objectId) {
      return null;
    }
    this.isUpdatingUsers = true;
    let updatedUser: User | null = null;
    try {
      updatedUser = await parseService.updateUser(params);
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.users) {
      this.users = {};
    }
    if (!updatedUser) {
      delete this.users[params.objectId];
    } else {
      this.users[params.objectId] = updatedUser;
    }
    this.isUpdatingUsers = false;
    return updatedUser;
  };

  @action
  public deleteUser = async (userId: User["objectId"]) => {
    let success = false;
    this.isDeletingUsers = true;
    try {
      await parseService.deleteUser(userId);
      success = true;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.users) {
      this.users = {};
    }
    if (success) {
      delete this.users[userId];
    }
    this.isDeletingUsers = false;
    return success;
  };

  @action
  public addRoleToUser = async (
    userId: User["objectId"],
    roleName: string
  ): Promise<boolean> => {
    this.isUpdatingUsers = true;
    let success = false;
    try {
      const isUpdated = await parseService.addRoleToUser(userId, roleName);
      if (isUpdated) {
        success = true;
        const roles = await parseService.fetchRoles();
        this.roles = _.keyBy(roles, "name");
      }
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.isUpdatingUsers = false;
    return success;
  };

  @action
  public removeRoleToUser = async (
    userId: User["objectId"],
    roleName: string
  ): Promise<boolean> => {
    this.isUpdatingUsers = true;
    let success = false;
    try {
      const isUpdated = await parseService.removeRoleToUser(userId, roleName);
      if (isUpdated) {
        success = true;
        const roles = await parseService.fetchRoles();
        this.roles = _.keyBy(roles, "name");
      }
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.isUpdatingUsers = false;
    return success;
  };

  public getUserRoleNames = (userId?: string | null): string[] => {
    if (userId == null) {
      return [];
    }
    const roles = this.roles || {};
    return Object.keys(roles).filter(
      roleName => !_.isNil(_.get(roles, `${roleName}.users.${userId}`))
    );
  };

  public getIsUserInRole = (
    userId?: string | null,
    roleName?: string | null
  ): boolean => {
    if (userId == null || roleName == null) {
      return false;
    }
    if (!this.roles) {
      return false;
    }
    const foundRole = this.roles[roleName];
    if (!foundRole) {
      return false;
    }
    const foundUser = foundRole.users[userId];
    return !!foundUser;
  };

  public getIsUserInRoles = (
    userId?: string | null,
    roleNames?: string[] | null
  ): boolean => {
    if (userId == null || roleNames == null) {
      return false;
    }
    return roleNames.every(roleName => this.getIsUserInRole(userId, roleName));
  };

  public getIsAdmin = (userId?: string | null): boolean => {
    if (userId == null) {
      return false;
    }
    return this.getIsUserInRole(userId, "admin");
  };

  public getIsTenantAdmin = (
    userId?: string | null,
    tenantId?: string | null
  ): boolean => {
    if (userId == null || tenantId == null) {
      return false;
    }
    return this.getIsUserInRole(userId, `tenantAdmin_${tenantId}`);
  };

  public getIsMuseumAdmin = (
    userId?: string | null,
    museumId?: string | null
  ): boolean => {
    if (userId == null || museumId == null) {
      return false;
    }
    return this.getIsUserInRole(userId, `museumAdmin_${museumId}`);
  };

  public getIsContentAdmin = (
    userId?: string | null,
    museumId?: string | null
  ): boolean => {
    if (userId == null || museumId == null) {
      return false;
    }
    return this.getIsUserInRole(userId, `contentAdmin_${museumId}`);
  };

  public getIsAnalyticsAdmin = (
    userId?: string | null,
    museumId?: string | null
  ): boolean => {
    if (userId == null || museumId == null) {
      return false;
    }
    return this.getIsUserInRole(userId, `statsAdmin_${museumId}`);
  };

  @computed
  get selectedUser(): User | null {
    if (!this.selectedUserId || !this.users) {
      return null;
    }
    return this.users[this.selectedUserId] || null;
  }
}
