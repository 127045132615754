import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import styled from "styled-components";

import stringsConfig from "../config/strings";
import Title from "./Title";

const grid = 8;

const StyledBoard = styled.div`
  display: flex;
`;

const StyledColumn = styled.div`
  flex: 1;
  margin-right: 1em;
  &:last-child {
    margin-right: 0;
  }
`;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "lightgrey" : "white",
  ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "grey" : "lightgrey",
  padding: grid,
  borderRadius: "3px"
});

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (
  source: any[],
  destination: any[],
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  const result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;
};

type Option = any;
type OptionId = any;

interface Props {
  value: OptionId[];
  onChange: (optionIds: OptionId[]) => any;
  options: Option[];
  getOptionId: (option: Option) => string;
  getOptionContent: (option: Option) => React.ReactNode;
}

const TransferList = ({
  value = [],
  onChange,
  options,
  getOptionId,
  getOptionContent
}: Props) => {
  const cards = options.map(option => ({
    id: getOptionId(option),
    content: getOptionContent(option)
  }));
  const state = {
    items: cards.filter(card => !value.includes(card.id)),
    selected: value
      .map(cardId => cards.find(card => card.id === cardId))
      .filter(card => !_.isUndefined(card))
  };
  const id2List = {
    droppable: "items",
    droppable2: "selected"
  };
  const getList = (id: keyof typeof id2List) =>
    // @ts-ignore
    id2List[id] && state[id2List[id]];

  const onDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) {
      // dropped outside the list
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );
      if (source.droppableId === "droppable2") {
        const newValue = items.map(item => item.id);
        onChange(newValue);
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      const newValue = result.droppable2.map((item: any) => item.id);
      onChange(newValue);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StyledBoard>
        <StyledColumn>
          <Title level={4}>
            {stringsConfig.components.transferList.availableOptions}
          </Title>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </StyledColumn>
        <StyledColumn>
          <Title level={4}>
            {stringsConfig.components.transferList.selectedOptions}
          </Title>
          <Droppable droppableId="droppable2">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {state.selected.map((item, index) => (
                  // @ts-ignore
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {
                          // @ts-ignore
                          item.content
                        }
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </StyledColumn>
      </StyledBoard>
    </DragDropContext>
  );
};

export default TransferList;
