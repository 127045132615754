import React from "react";

type Props = {
  src?: any;
  controls?: boolean;
};

const Audio: React.FC<Props> = ({ src, controls = true }) => {
  return <audio src={src} controls={controls} style={{ width: "100%" }} />;
};

export default Audio;
