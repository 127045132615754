import React, { useCallback, useState } from "react";
import { Field as FormikField, useFormikContext } from "formik";
import _ from "lodash";
import { Composition } from "atomic-layout";

import Input from "./Input";
import Text from "./Text";
import Title from "./Title";

type FieldTitleLabelProps = {
  title?: string;
  required?: boolean;
  htmlFor?: string;
};

const FieldTitleLabel = ({
  title = "",
  required = false,
  htmlFor = ""
}: FieldTitleLabelProps) => {
  return (
    <label htmlFor={htmlFor}>
      <Title level={4}>{`${title}${required ? "*" : ""}`}</Title>
    </label>
  );
};

type FieldErrorLabelProps = {
  children?: string;
  htmlFor?: string;
};

const FieldErrorLabel = ({ children, htmlFor }: FieldErrorLabelProps) => {
  return (
    <label htmlFor={htmlFor}>
      <Text type="danger">{children}</Text>
    </label>
  );
};

type FieldWrapperProps = React.ComponentProps<typeof FormikField> & {
  innerComponent: React.ReactNode;
  label?: string;
  required?: boolean;
};

const areasMobile = `
titleLabel
field
errorLabel
`;

const areasDesktop = `
leftSpace titleLabel field rightSpace
leftSpace titleLabel errorLabel rightSpace
`;

const FieldWrapper = ({
  name,
  value,
  onChange,
  onBlur,
  innerComponent: Component,
  required = false,
  label,
  fieldLanguages = [],
  fieldSelectedLanguage,
  ...restProps
}: FieldWrapperProps) => {
  const {
    setFieldValue,
    setFieldTouched,
    errors,
    submitCount,
    touched
  } = useFormikContext<any>();
  const fieldError = _.get(errors, name);
  const isFieldTouched = !!_.get(touched, name) || submitCount > 0;

  const [innerValue, setInnerValue] = useState(value);

  const setOuterValue = useCallback(
    (value: any) => {
      setFieldTouched && setFieldTouched(name, true);
      setFieldValue && setFieldValue(name, value);
    },
    [setFieldTouched, setFieldValue, name]
  );

  const handleChange = useCallback(
    (value: any) => {
      setInnerValue((innerValue: any) => {
        let nextInnerValue = value;
        if (fieldLanguages.length > 0) {
          nextInnerValue = _.merge({}, innerValue, {
            [fieldSelectedLanguage]: value
          });
        }
        if (Component !== Input) {
          setOuterValue(nextInnerValue);
        }
        return nextInnerValue;
      });
    },
    [
      setInnerValue,
      setOuterValue,
      Component,
      fieldLanguages,
      fieldSelectedLanguage
    ]
  );

  const handleBlur = useCallback(() => {
    setOuterValue(innerValue);
  }, [innerValue, setOuterValue]);

  const handleKeyPress = useCallback(
    (event: any) => {
      if ((event.keyCode || event.which) === 13) {
        setOuterValue(innerValue);
      }
    },
    [innerValue, setOuterValue]
  );

  return (
    <Composition
      areas={areasMobile}
      areasLg={areasDesktop}
      templateCols="1fr"
      templateColsLg="1fr 1fr 3fr 1fr"
      gap={15}
      style={{ marginBottom: "2em" }}
    >
      {({ LeftSpace, RightSpace, ErrorLabel, Field, TitleLabel }) => (
        <React.Fragment>
          <LeftSpace>
            <span />
          </LeftSpace>
          <TitleLabel>
            <FieldTitleLabel title={label} required={required} htmlFor={name} />
          </TitleLabel>
          <Field>
            <Component
              {...restProps}
              onKeyPress={handleKeyPress}
              id={name}
              name={name}
              value={
                fieldLanguages.length > 0
                  ? _.get(innerValue, fieldSelectedLanguage)
                  : innerValue
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Field>
          <ErrorLabel>
            {isFieldTouched && fieldError != null && (
              <FieldErrorLabel>{String(fieldError)}</FieldErrorLabel>
            )}
          </ErrorLabel>
          <RightSpace>
            <span />
          </RightSpace>
        </React.Fragment>
      )}
    </Composition>
  );
};

type FormFieldProps = React.ComponentProps<typeof FormikField> & {
  label?: string;
  required?: boolean;
};

export default ({
  as,
  validate,
  required = false,
  ...restProps
}: FormFieldProps) => {
  const handleValidation = useCallback(
    (value: any) => {
      const isEmptyValue =
        _.isNil(value) || (_.isString(value) && !value.trim());
      if (required && isEmptyValue) {
        return "Questo campo è obbligatorio";
      }
      return validate && validate();
    },
    [validate, required]
  );
  return (
    <FormikField
      {...restProps}
      as={FieldWrapper}
      innerComponent={as}
      validate={handleValidation}
      required={required}
    />
  );
};
