import React, { useCallback, useState, useEffect } from "react";
import _ from "lodash";

import parseService from "../../services/parse";
import loggerService from "../../services/logger";
import FilePreview from "../File";
import Icon from "../Icon";
import Modal from "../Modal";
import Popconfirm from "../Popconfirm";
import Spinner from "../Spinner";
import Styled from "./styled";
import { ArtworkMultimedia } from "../../types/artwork";
import { ParseFile } from "../../types/parse";

const defaultProps = {
  uploadAction: async (files: File[]): Promise<ParseFile[]> => {
    const uploadPromises = files.map(file =>
      // TODO: for artwork multimedia create structure as {file, description}
      parseService.uploadFile(file.name, file).catch(() => undefined)
    );
    const uploadedFiles = (await Promise.all(uploadPromises)).filter(
      (file): file is ParseFile => !_.isUndefined(file)
    );
    return uploadedFiles;
  },
  deleteAction: async (files: RemoteFile[]) => {
    await new Promise(r => setTimeout(r, 1500));
  },
  getFileSrc: (file: RemoteFile) => _.get(file, "url"),
  onError: loggerService.error,
  filesMaxSize: 50 // 50 MB
};

const getIsValidFileType = (file: File, mimeTypes?: string[]) => {
  if (!mimeTypes) {
    return true;
  }
  return mimeTypes.map(mime => mime.toLowerCase()).indexOf(file.type) !== -1;
};

const getIsValidFileSize = (file: File, filesMaxSize?: number) => {
  if (!filesMaxSize) {
    return true;
  }
  return file.size / 1024 / 1024 <= filesMaxSize;
};

type RemoteFile = ParseFile;

type UploadProps = {
  value?: RemoteFile | RemoteFile[];
  uploadAction?: (files: File[]) => Promise<ParseFile[]>;
  deleteAction?: (files: RemoteFile[]) => Promise<void>;
  onChange?: (value: RemoteFile[]) => void;
  onError?: (message: string) => void;
  getFileSrc?: (file: RemoteFile) => string;
  filesMaxCount?: number;
  filesMaxSize?: number; // Max allowed file size in MB
  mimeTypes?: string[];
};

export default ({
  value,
  uploadAction = defaultProps.uploadAction,
  deleteAction = defaultProps.deleteAction,
  onChange,
  onError = defaultProps.onError,
  filesMaxCount,
  filesMaxSize = defaultProps.filesMaxSize,
  mimeTypes,
  getFileSrc = defaultProps.getFileSrc
}: UploadProps) => {
  const getFileListFromValue = (value?: RemoteFile | RemoteFile[]) =>
    _.isArray(value) ? value : _.isNil(value) ? [] : [value];
  const [fileList, setFileList] = useState(getFileListFromValue(value));
  const [previewFile, setPreviewFile] = useState<RemoteFile>();
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [indicesBeingLoaded, setIndicesBeingLoaded] = useState<number[]>([]);

  useEffect(() => {
    setFileList(getFileListFromValue(value));
  }, [value, setFileList]);

  const renderFilePreview = useCallback(
    (file?: RemoteFile) => {
      const fileSrc = getFileSrc && file ? getFileSrc(file) : "";
      return <FilePreview src={fileSrc} />;
    },
    [getFileSrc]
  );

  const handleCloseModal = () => setIsPreviewVisible(false);

  const handleOpenModal = async (file: RemoteFile) => {
    setPreviewFile(file);
    setIsPreviewVisible(true);
  };

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files || []);
      const handleUploadAction = async (files: File[]) => {
        const hasInvalidFileTypes = files.some(
          file => !getIsValidFileType(file, mimeTypes)
        );
        const hasInvalidFileSizes = files.some(
          file => !getIsValidFileSize(file, filesMaxSize)
        );
        if (mimeTypes && hasInvalidFileTypes && onError) {
          onError(
            `I tipi di file accettati per questo upload sono: ${mimeTypes.join(
              ", "
            )}`
          );
        }
        if (filesMaxSize && hasInvalidFileSizes && onError) {
          onError(
            `È possibile eseguire upload di file con dimensione massima di ${filesMaxSize}MB`
          );
        }
        if (hasInvalidFileTypes || hasInvalidFileSizes) {
          return;
        }
        if (uploadAction) {
          setIsUploading(true);
          try {
            const uploadedFiles = await uploadAction(files);
            setFileList(fileList => {
              let newFileList = uploadedFiles
                ? fileList.concat(uploadedFiles)
                : fileList;
              newFileList =
                filesMaxCount != null
                  ? newFileList.slice(0, filesMaxCount)
                  : newFileList;
              if (filesMaxCount === 1) {
                onChange && onChange(_.get(newFileList, "0"));
              } else {
                onChange && onChange(newFileList);
              }
              return newFileList;
            });
          } catch (err) {
            onError && onError(_.get(err, "message", JSON.stringify(err)));
          }
          setIsUploading(false);
        }
      };
      handleUploadAction(files);
    },
    [
      uploadAction,
      onError,
      setIsUploading,
      setFileList,
      onChange,
      filesMaxCount,
      filesMaxSize,
      mimeTypes
    ]
  );

  const handleDeleteItems = useCallback(
    (filesToDelete: RemoteFile[], indicesToDelete) => {
      const handleDeleteAction = async () => {
        if (deleteAction) {
          setIndicesBeingLoaded(indicesToDelete);
          try {
            await deleteAction(filesToDelete);
            setFileList(fileList => {
              const newFileList = fileList.filter(
                (file, index) => !filesToDelete.includes(file)
              );
              if (filesMaxCount === 1) {
                onChange && onChange(_.get(newFileList, "0"));
              } else {
                onChange && onChange(newFileList);
              }
              return newFileList;
            });
          } catch (err) {
            onError && onError(_.get(err, "message", JSON.stringify(err)));
          }
          setIndicesBeingLoaded([]);
        }
      };
      handleDeleteAction();
    },
    [
      deleteAction,
      setIndicesBeingLoaded,
      onChange,
      setFileList,
      onError,
      filesMaxCount
    ]
  );

  const isUploadButtonVisible =
    filesMaxCount == null || fileList.length < filesMaxCount;

  return (
    <Styled.UploadContainer>
      {fileList &&
        fileList.map((file, index) => (
          <Styled.UploadItem key={`${index}`}>
            {indicesBeingLoaded.includes(index) ? (
              <Spinner />
            ) : (
              <React.Fragment>
                {renderFilePreview(file)}
                <Styled.UploadItemOverlay>
                  <Icon
                    style={{ fontSize: "2em", color: "white" }}
                    type="eye"
                    onClick={() => handleOpenModal(file)}
                  />
                  <Popconfirm
                    placement="leftTop"
                    title={"Sei sicuro di eliminarlo?"}
                    onConfirm={() => handleDeleteItems([file], [index])}
                    okText="Procedi"
                    cancelText="Torna indietro"
                  >
                    <Icon
                      style={{ fontSize: "2em", color: "white" }}
                      type="delete"
                    />
                  </Popconfirm>
                </Styled.UploadItemOverlay>
              </React.Fragment>
            )}
          </Styled.UploadItem>
        ))}
      {isUploadButtonVisible && (
        <Styled.UploadButton>
          {isUploading ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <Icon type="plus" />
              <div>Upload</div>
            </React.Fragment>
          )}
          <input
            multiple={true}
            type="file"
            onChange={handleInputChange}
            disabled={isUploading}
          />
        </Styled.UploadButton>
      )}
      <Modal
        visible={isPreviewVisible}
        footer={null}
        onCancel={handleCloseModal}
      >
        {renderFilePreview(previewFile)}
      </Modal>
    </Styled.UploadContainer>
  );
};
