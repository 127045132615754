import React from "react";
import { Spin } from "antd";

export default (props: React.ComponentProps<typeof Spin>) => {
  return (
    <div style={{ height: "100%", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <Spin {...props} />
      </div>
    </div>
  );
};
