import React from "react";
import { NavLink } from "react-router-dom";

const Link = ({
  children,
  ...restProps
}: React.ComponentProps<typeof NavLink>) => {
  return (
    <NavLink
      {...restProps}
      exact={true}
      activeStyle={{
        pointerEvents: "none"
      }}
    >
      {children}
    </NavLink>
  );
};

export default Link;
