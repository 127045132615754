import _ from "lodash";

/**
 * Generare a RFC4122 version 4 compliant UUID.
 * @return {string} The generated UUID string
 */
const uuidv4 = (): string => {
  let d = new Date().getTime();
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now(); //use high-precision timer if available
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

const isAudioSrc = (fileSrc: any): boolean =>
  _.isString(fileSrc) &&
  (fileSrc.startsWith("data:audio") ||
    /\.(mp3|mpga|aac|oga|ogg|wav|weba|3gp|3g2)$/gim.test(fileSrc));

const isImageSrc = (fileSrc: any): boolean =>
  _.isString(fileSrc) &&
  (fileSrc.startsWith("data:image") ||
    /\.(png|jpg|jpeg|svg|gif|ico|tif|tiff|webp)$/gim.test(fileSrc));

const isVideoSrc = (fileSrc: any): boolean =>
  _.isString(fileSrc) &&
  (fileSrc.startsWith("data:video") ||
    /\.(mpeg|mp4|mov|avi|ogv|webm|3gp|3g2)$/gim.test(fileSrc));

export default {
  uuidv4,
  isAudioSrc,
  isImageSrc,
  isVideoSrc
};
