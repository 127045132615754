import React from "react";
import _ from "lodash";

import Button from "../Button";
import Icon from "../Icon";
import Input from "../Input";
import Styled from "./styled";
import { ExcludeProps } from "../../utils/typescript";
import { Coordinates } from "../../types/geolocation";

type MaybeCoordinates = Partial<Coordinates>;

const defaultCoordinates = {
  latitude: undefined,
  longitude: undefined
};

type InputFilteredProps = ExcludeProps<
  React.ComponentProps<typeof Input>,
  "value" | "onChange"
>;

type Props = InputFilteredProps & {
  value?: MaybeCoordinates;
  onChange?: (value: MaybeCoordinates) => any;
};

export default React.forwardRef(
  ({ value, onChange, ...restProps }: Props, ref: React.Ref<any>) => {
    const coordinates: MaybeCoordinates = _.isNil(value)
      ? defaultCoordinates
      : value;
    const handleChange = (coordinates: MaybeCoordinates) => {
      onChange && onChange(coordinates);
    };
    const handleDelete = () => {
      onChange && onChange(defaultCoordinates);
    };
    return (
      <Styled.GeolocationTable ref={ref}>
        <thead>
          <tr>
            <th>Latitudine</th>
            <th>Longitudine</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Input
                {...restProps}
                type="number"
                value={coordinates && coordinates.latitude}
                min={-90}
                max={90}
                onChange={(value: any) =>
                  handleChange({
                    latitude:
                      value != null && !isNaN(Number(value))
                        ? Number(value)
                        : undefined,
                    longitude: coordinates && coordinates.longitude
                  })
                }
              />
            </td>
            <td>
              <Input
                {...restProps}
                type="number"
                value={coordinates && coordinates.longitude}
                min={-180}
                max={180}
                onChange={(value: any) =>
                  handleChange({
                    latitude: coordinates && coordinates.latitude,
                    longitude:
                      value != null && !isNaN(Number(value))
                        ? Number(value)
                        : undefined
                  })
                }
              />
            </td>
            <td>
              <Button onClick={handleDelete}>
                <Icon type="delete" />
              </Button>
            </td>
          </tr>
        </tbody>
      </Styled.GeolocationTable>
    );
  }
);
