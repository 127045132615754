import _ from "lodash";
import { message } from "antd";

import keysConfig from "../config/keys";

const stringify = (data: any) =>
  _.isString(data) ? data : JSON.stringify(data);

interface Logger {
  loading: (data: any) => void;
  success: (data: any) => void;
  warning: (data: any) => void;
  error: (data: any) => void;
  info: (data: any) => void;
}

let logger: Logger = {
  loading: (text: string) => message.loading(stringify(text)),
  success: (text: string) => message.success(stringify(text)),
  warning: (text: string) => message.warning(stringify(text)),
  error: (text: string) => message.error(stringify(text)),
  info: (text: string) => message.info(stringify(text))
};

if (process.env.NODE_ENV === "test") {
  logger = {
    loading: _.noop,
    success: _.noop,
    warning: _.noop,
    error: _.noop,
    info: _.noop
  };
}

if (keysConfig.IS_ENV_PRODUCTION) {
  // TODO: use a remote service to stash logs
}

export default logger;
