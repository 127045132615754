import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import objectUtils from "../utils/object";
import Checklist from "../components/Checklist";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Input from "../components/Input";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import SchedulePicker from "../components/SchedulePicker";
import Spinner from "../components/Spinner";
import Tabs from "../components/Tabs";
import TicketsPicker from "../components/TicketsPicker";
import Upload from "../components/Upload";
import GeolocationPicker from "../components/GeolocationPicker";

import { Stores } from "../types/stores";
import { Museum } from "../types/museum";
import { Tenant } from "../types/tenant";
import { Language } from "../types/language";

const getFormValues = (museum: Museum | null, tenant: Tenant | null) => {
  return {
    ..._.omit(museum, ["tenant"]),
    tenant
  };
};

interface OwnProps {
  location: Location;
  history: History;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps) => {
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const museumId = navigationUtils.fromRoutes.museumId(
    ownProps.location.pathname
  );
  const museum = (museumId && _.get(stores.museums.museums, museumId)) || null;
  const tenant = (tenantId && _.get(stores.tenants.tenants, tenantId)) || null;
  const isCreatingMuseum = museumId === navigationUtils.routesUtils.pathCreate;
  const currentUserId = _.get(stores.auth.user, "objectId");
  const canSaveMuseum = isCreatingMuseum
    ? stores.users.getIsAdmin(currentUserId)
    : stores.users.getIsAdmin(currentUserId) ||
      stores.users.getIsTenantAdmin(currentUserId, tenantId) ||
      stores.users.getIsMuseumAdmin(currentUserId, museumId) ||
      stores.users.getIsContentAdmin(currentUserId, museumId);
  return {
    tenantId,
    museumId,
    museum,
    tenant,
    fetchMuseums: stores.museums.fetchMuseums,
    fetchTenants: stores.tenants.fetchTenants,
    createMuseum: stores.museums.createMuseum,
    updateMuseum: stores.museums.updateMuseum,
    isCreatingMuseum,
    isLoading:
      stores.museums.isFetchingMuseums || stores.tenants.isFetchingTenants,
    canSaveMuseum,
    isSuperAdmin: stores.auth.isSuperAdmin
  };
};

type StoresProps = ReturnType<typeof mapStoresToProps>;

const TenantDetailScreen = ({
  history,
  location,
  tenantId,
  museumId,
  museum,
  tenant,
  fetchMuseums,
  fetchTenants,
  createMuseum,
  updateMuseum,
  isCreatingMuseum,
  isLoading,
  canSaveMuseum,
  isSuperAdmin
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchMuseums();
    fetchTenants();
  }, [fetchMuseums, fetchTenants]);

  const [formValues, setFormValues] = useState(getFormValues(museum, tenant));

  useEffect(() => {
    setFormValues(getFormValues(museum, tenant));
  }, [setFormValues, museum, tenant]);

  const handleValidSubmit = useCallback(
    (values: any) => {
      if (!canSaveMuseum) {
        return loggerService.error("Salvataggio non autorizzato");
      }
      if (!tenantId || !museumId) {
        return loggerService.error("Errore nel salvataggio");
      }
      if (!isCreatingMuseum && !museum) {
        return loggerService.error("Errore nel salvataggio");
      }
      const handleSaveMuseum = async () => {
        try {
          const params: any = objectUtils.pickOrUndefined(values, [
            "name",
            "languages",
            "coverImage",
            "slideshow",
            "info",
            "areas",
            "beacons",
            "order",
            "routes",
            "routeTags",
            "qrCode",
            "insightInvitationAudio",
            "enabledFeatures"
          ]);
          if (museum) {
            params.objectId = museum.objectId;
          }
          let savedMuseum: Museum | null = null;
          if (isCreatingMuseum) {
            // @ts-ignore
            savedMuseum = await createMuseum(params, tenantId);
          } else {
            savedMuseum = await updateMuseum(params);
          }
          if (!savedMuseum) {
            return loggerService.error("Errore nel salvataggio");
          }
          loggerService.success("Salvataggio riuscito");
          navigationUtils.goTo(
            { history, location },
            navigationUtils.routes.museum.find(tenantId, museumId)
          );
        } catch (err) {
          loggerService.error("Errore nel salvataggio");
        }
      };
      return handleSaveMuseum();
    },
    [
      canSaveMuseum,
      isCreatingMuseum,
      museum,
      createMuseum,
      updateMuseum,
      museumId,
      tenantId,
      history,
      location
    ]
  );

  const [selectedLanguage, setSelectedLanguage] = useState("it");

  if (isLoading || !tenantId || !museumId || (!museum && !isCreatingMuseum)) {
    return <Spinner />;
  }

  const languages: Language[] = _.get(formValues, `languages`, []);
  const firstAvailableLanguage: Language | undefined = _.get(
    languages,
    "0",
    undefined
  );

  const pageTitle = isCreatingMuseum
    ? "Crea nuovo museo"
    : _.get(museum, `name.it`) ||
      (firstAvailableLanguage &&
        _.get(museum, `name.${firstAvailableLanguage}`)) ||
      "Modifica museo";

  return (
    <Layout style={{ height: "100%" }}>
      <PageHeader title={pageTitle} />
      <Form
        key={`${formValues.objectId}${_.get(formValues, "tenant.objectId")}`}
        initialValues={formValues}
        onSubmit={handleValidSubmit}
        hasSubmitButton={canSaveMuseum}
      >
        <Tabs defaultActiveKey="it" onChange={setSelectedLanguage}>
          {languages.map(language => (
            <Tabs.TabPane tab={language} key={language} />
          ))}
        </Tabs>
        <FormField
          name={`name`}
          label="Nome"
          required
          as={Input}
          type="text"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name="tenant.name"
          label="Cliente di appartenenza"
          as={Input}
          type="text"
          required
          disabled
        />
        <FormField
          name="coverImage"
          label="Immagine di copertina"
          as={Upload}
          mimeTypes={["image/jpeg", "image/png"]}
          filesMaxCount={1}
        />
        <FormField
          name="slideshow"
          label="Immagini di presentazione"
          mimeTypes={["image/jpeg", "image/png", "video/mp4"]}
          as={Upload}
        />
        <FormField
          name={`info.welcomeMessage`}
          label="Messaggio di benvenuto"
          as={Input}
          type="textarea"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`info.visitingTitle`}
          label="Titolo durante la visita"
          as={Input}
          type="text"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`info.blurb`}
          label="Descrizione breve"
          as={Input}
          type="textarea"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`info.story`}
          label="Storia"
          as={Input}
          type="textarea"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`info.aboutUs`}
          label="Chi siamo: Descrizione"
          as={Input}
          type="textarea"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        {/* TODO: use Places to autofill this on info.address change; then lock this input */}
        <FormField
          name="info.addressCoordinate"
          label="Chi siamo: Posizione"
          as={GeolocationPicker}
        />
        <FormField
          name={`info.directions`}
          label="Indicazioni per arrivare"
          as={Input}
          type="textarea"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name="info.phoneNumber"
          label="Contatti: Numero di telefono"
          as={Input}
          type="text"
        />
        <FormField
          name="info.address"
          label="Contatti: Indirizzo"
          as={Input}
          type="text"
        />
        <FormField
          name="info.schedule.monday"
          label="Orari lunedì"
          as={SchedulePicker}
        />
        <FormField
          name="info.schedule.tuesday"
          label="Orari martedì"
          as={SchedulePicker}
        />
        <FormField
          name="info.schedule.wednesday"
          label="Orari mercoledì"
          as={SchedulePicker}
        />
        <FormField
          name="info.schedule.thursday"
          label="Orari giovedì"
          as={SchedulePicker}
        />
        <FormField
          name="info.schedule.friday"
          label="Orari venerdì"
          as={SchedulePicker}
        />
        <FormField
          name="info.schedule.saturday"
          label="Orari sabato"
          as={SchedulePicker}
        />
        <FormField
          name="info.schedule.sunday"
          label="Orari domenica"
          as={SchedulePicker}
        />
        <FormField
          name={`info.schedule.extraText`}
          label="Info aggiuntive orari"
          as={Input}
          type="textarea"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`info.tickets.options`}
          label="Biglietti"
          as={TicketsPicker}
          language={selectedLanguage}
        />
        <FormField
          name={`info.tickets.purchaseUrl`}
          label="Link URL per l'acquisto biglietti"
          as={Input}
          type="text"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`info.tickets.extraText`}
          label="Info aggiuntive biglietti"
          as={Input}
          type="textarea"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`insightInvitationAudio`}
          label="Audio di invito all'approfondimento"
          as={Upload}
          mimeTypes={["audio/mpeg", "audio/mp3"]}
          filesMaxCount={1}
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name="info.newsUrl"
          label="Link alle notizie"
          as={Input}
          type="text"
        />
        <FormField
          name="info.social.instagramUrl"
          label="Link alla pagina Instagram"
          as={Input}
          type="text"
        />
        <FormField
          name="info.social.facebookUrl"
          label="Link alla pagina Facebook"
          as={Input}
          type="text"
        />
        <FormField
          name="info.social.twitterUrl"
          label="Link alla pagina Twitter"
          as={Input}
          type="text"
        />
        {isSuperAdmin && (
          <FormField
            name={"enabledFeatures"}
            label="Feature abilitate"
            as={Checklist}
          />
        )}
        <FormField
          name={"qrCode"}
          label="Codice QR"
          as={Input}
          type="qrcode"
          required
        />
        <FormField
          name="languages"
          label="Lingue supportate"
          as={Input}
          type="text"
          disabled
        />
        <FormField name="order" label="Ordine" as={Input} type="number" />
      </Form>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(TenantDetailScreen));
