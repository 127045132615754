import getRuntimeEnv from "@mars/heroku-js-runtime-env";

const runtimeEnv = getRuntimeEnv();

const defaultEnv = {
  REACT_APP_PARSE_APP_ID: "LOCAL_APP_ID",
  REACT_APP_PARSE_SERVER_URL: "http://localhost:1337/api"
};

const mergedEnv = Object.assign({}, defaultEnv, process.env, runtimeEnv);

export default {
  IS_ENV_PRODUCTION: mergedEnv.NODE_ENV === "production",
  PARSE_APP_ID: mergedEnv.REACT_APP_PARSE_APP_ID,
  PARSE_SERVER_URL: mergedEnv.REACT_APP_PARSE_SERVER_URL
};
