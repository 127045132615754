import styled from "styled-components";

const UploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const UploadItem = styled.div`
  position: relative;
  background: white;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12em;
  height: 12em;
  box-shadow: 0px 0px 0.5em rgba(0, 0, 0, 0.3);
  margin: 0.5em;
`;

const UploadButton = styled.label`
  position: relative;
  background: white;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12em;
  height: 12em;
  box-shadow: 0px 0px 0.5em rgba(0, 0, 0, 0.3);
  margin: 0.5em;
  input {
    height: 0;
    width: 0;
    display: none;
  }
`;

const UploadItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  &:hover {
    opacity: 1;
  }
`;

export default {
  UploadContainer,
  UploadItem,
  UploadItemOverlay,
  UploadButton
};
