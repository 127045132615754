import { useState, useCallback, useLayoutEffect } from "react";

export interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

export type UseDimensionsHook = [
  (node: HTMLElement | null) => void,
  DimensionObject,
  HTMLElement | null
];

export interface UseDimensionsArgs {
  liveMeasure?: boolean;
}

const getDimensionObject = (node: HTMLElement): DimensionObject => {
  const rect = node.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height,
    // @ts-ignore
    top: "x" in rect ? rect.x : rect.top,
    // @ts-ignore
    left: "y" in rect ? rect.y : rect.left,
    // @ts-ignore
    x: "x" in rect ? rect.x : rect.left,
    // @ts-ignore
    y: "y" in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom
  };
};

const useDimensions = ({
  liveMeasure = true
}: UseDimensionsArgs = {}): UseDimensionsHook => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    x: 0,
    y: 0,
    right: 0,
    bottom: 0
  });
  const [node, setNode] = useState(null);
  const ref = useCallback(node => {
    setNode(node);
  }, []);
  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          // @ts-ignore
          setDimensions(getDimensionObject(node))
        );
      measure();

      if (liveMeasure) {
        window.addEventListener("resize", measure);
        window.addEventListener("scroll", measure);

        return () => {
          window.removeEventListener("resize", measure);
          window.removeEventListener("scroll", measure);
        };
      }
    }
    return () => {};
  }, [node, liveMeasure]);
  return [ref, dimensions, node];
};

export default useDimensions;
