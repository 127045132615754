import dayjs from "dayjs";
import _ from "lodash";

const getSortFunction = <ItemType>(
  propertyGetter: keyof ItemType | string | ((item: ItemType) => any)
) => (itemA: ItemType, itemB: ItemType) => {
  const propertyA = _.isFunction(propertyGetter)
    ? propertyGetter(itemA)
    : _.get(itemA, propertyGetter);
  const propertyB = _.isFunction(propertyGetter)
    ? propertyGetter(itemB)
    : _.get(itemB, propertyGetter);
  return propertyA < propertyB ? -1 : propertyA === propertyB ? 0 : 1;
};

const prettifyDate = (v: any): string => {
  const parsed = dayjs(v);
  return !v || !parsed.isValid() ? "" : parsed.format("YYYY-MM-DD");
};

const prettifyDateTime = (v: any): string => {
  const parsed = dayjs(v);
  return !v || !parsed.isValid() ? "" : parsed.format("YYYY-MM-DD HH:mm:ss");
};

const prettifyTime = (v: any): string => {
  const parsed = dayjs(v);
  return !v || !parsed.isValid() ? "" : parsed.format("HH:mm:ss");
};

/**
 * Generare a RFC4122 version 4 compliant UUID.
 * @return {string} The generated UUID string
 */
const uuidv4 = (): string => {
  let d = new Date().getTime();
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now(); //use high-precision timer if available
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export default {
  uuidv4,
  getSortFunction,
  prettifyDate,
  prettifyDateTime,
  prettifyTime
};
