import React from "react";
import { Card } from "antd";
import _ from "lodash";

import Button from "../Button";
import Icon from "../Icon";
import Input from "../Input";
import Popconfirm from "../Popconfirm";
import Styled from "./styled";
import { ExcludeProps } from "../../utils/typescript";
import { Language, Translated } from "../../types/language";

interface DescriptionSection {
  title?: Translated<string | undefined>;
  text?: Translated<string | undefined>;
}

const emptySection: DescriptionSection = {
  title: undefined,
  text: undefined
};

type AntdCardFilteredProps = ExcludeProps<
  React.ComponentProps<typeof Card>,
  "value" | "onChange"
>;

type Props = AntdCardFilteredProps & {
  value?: DescriptionSection[];
  onChange?: (value: DescriptionSection[]) => any;
  onMainAudioGenerationRequest: (languageCode: string) => Promise<any>;
  language: Language;
};

export default React.forwardRef(
  (
    {
      value,
      onChange,
      language,
      onMainAudioGenerationRequest,
      ...restProps
    }: Props,
    ref: React.Ref<any>
  ) => {
    const descriptionSections: DescriptionSection[] =
      _.isArray(value) && value.length > 0 ? value : [emptySection];
    const handleChange = (
      index: number,
      descriptionSection: DescriptionSection
    ) => {
      const newDescriptionSections = descriptionSections.slice();
      newDescriptionSections[index] = descriptionSection;
      onChange && onChange(newDescriptionSections);
    };
    const handleDelete = (index: number) => {
      const newDescriptionSections = descriptionSections.slice();
      newDescriptionSections.splice(index, 1);
      if (newDescriptionSections.length === 0) {
        newDescriptionSections.push(emptySection);
      }
      onChange && onChange(newDescriptionSections);
    };
    const handleAdd = () => {
      const newDescriptionSections = descriptionSections.slice();
      newDescriptionSections.push(emptySection);
      onChange && onChange(newDescriptionSections);
    };
    return (
      <Styled.DescriptionContainer>
        {descriptionSections.map((descriptionSection, index) => (
          <Styled.DescriptionSectionContainer key={`${index}`}>
            <Card
              ref={ref}
              title={
                // @ts-ignore
                <Input
                  {...restProps}
                  type="text"
                  value={_.get(descriptionSection, `title.${language}`)}
                  onChange={(value: any) =>
                    handleChange(
                      index,
                      _.merge({}, descriptionSection, {
                        title: {
                          [language]: value
                        }
                      })
                    )
                  }
                />
              }
              extra={
                <Button onClick={() => handleDelete(index)}>
                  Elimina
                  <Icon type="delete" />
                </Button>
              }
            >
              {
                // @ts-ignore
                <Input
                  {...restProps}
                  type="textarea"
                  value={_.get(descriptionSection, `text.${language}`)}
                  onChange={(value: any) =>
                    handleChange(
                      index,
                      _.merge({}, descriptionSection, {
                        text: {
                          [language]: value
                        }
                      })
                    )
                  }
                />
              }
            </Card>
          </Styled.DescriptionSectionContainer>
        ))}
        <Button onClick={handleAdd}>
          Aggiungi paragrafo
          <Icon type="plus" />
        </Button>
        <Popconfirm
          title={
            <div>
              <p>
                Il punto di interesse verrà salvato prima di generare l'audio.
              </p>
              <p>Vuoi proseguire?</p>
            </div>
          }
          onConfirm={() => onMainAudioGenerationRequest(language)}
          onCancel={() => {}}
          okText="Genera l'audio"
          cancelText="Torna indietro"
        >
          <Button>
            Genera il file audio principale ({language})
            <Icon type="audio" />
          </Button>
        </Popconfirm>
      </Styled.DescriptionContainer>
    );
  }
);
