import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import { Provider as StoresProvider } from "mobx-react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { inject, observer } from "mobx-react";

import navigationUtils from "./utils/navigation";
import parseService from "./services/parse";
import AuthStore from "./stores/Auth";
import UsersStore from "./stores/Users";
import TenantsStore from "./stores/Tenants";
import MuseumsStore from "./stores/Museums";
import ArtworksStore from "./stores/Artworks";
import SurveysStore from "./stores/Surveys";
import Spinner from "./components/Spinner";
import LoginScreen from "./screens/Login";
import App from "./App";
import "./index.css";
import { Stores } from "./types/stores";
import * as serviceWorker from "./serviceWorker";

const authStore = new AuthStore();
const usersStore = new UsersStore(authStore);
const tenantsStore = new TenantsStore(authStore);
const museumsStore = new MuseumsStore(authStore);
const artworksStore = new ArtworksStore(authStore);
const surveysStore = new SurveysStore(authStore);

type PrivateRouteProps = React.ComponentProps<typeof Route> & {
  component: React.ComponentType<any>;
  isAuthenticated: boolean;
};

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}: PrivateRouteProps) => {
  const renderRoute = useCallback(
    props =>
      isAuthenticated ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: navigationUtils.routes.auth.login(),
            state: { from: props.location }
          }}
        />
      ),
    [isAuthenticated, rest]
  );
  return <Route {...rest} render={renderRoute} />;
};

interface AppInitializerProps {
  initializeAuth: () => Promise<void>;
  initializeUsers: () => Promise<void>;
  initializeTenants: () => Promise<void>;
  initializeMuseums: () => Promise<void>;
  initializeArtworks: () => Promise<void>;
  initializeSurveys: () => Promise<void>;
  isInitialized: boolean;
  isAuthenticated: boolean;
}

const mapStoresToProps = (stores: Stores) => ({
  initializeAuth: stores.auth.initialize,
  initializeUsers: stores.users.initialize,
  initializeTenants: stores.tenants.initialize,
  initializeMuseums: stores.museums.initialize,
  initializeArtworks: stores.artworks.initialize,
  initializeSurveys: stores.surveys.initialize,
  isInitialized:
    stores.auth.isInitialized &&
    stores.users.isInitialized &&
    stores.tenants.isInitialized &&
    stores.museums.isInitialized &&
    stores.artworks.isInitialized &&
    stores.surveys.isInitialized,
  isAuthenticated: stores.auth.isLoggedIn
});

@inject(mapStoresToProps)
@observer
class AppInitializer extends React.Component<AppInitializerProps> {
  componentDidMount() {
    this.initializeApp();
  }

  initializeApp = async () => {
    await parseService.initialize();
    await Promise.all([
      this.props.initializeAuth(),
      this.props.initializeUsers(),
      this.props.initializeTenants(),
      this.props.initializeMuseums(),
      this.props.initializeArtworks(),
      this.props.initializeSurveys()
    ]);
  };

  render() {
    const { isInitialized, isAuthenticated } = this.props;
    if (!isInitialized) {
      return <Spinner />;
    }

    return (
      <Switch>
        <Route
          path={navigationUtils.routes.auth.login()}
          component={LoginScreen}
        />
        <PrivateRoute
          path={"/"}
          component={App}
          isAuthenticated={isAuthenticated}
        />
      </Switch>
    );
  }
}

ReactDOM.render(
  <Router>
    <StoresProvider
      auth={authStore}
      users={usersStore}
      tenants={tenantsStore}
      museums={museumsStore}
      artworks={artworksStore}
      surveys={surveysStore}
    >
      {
        // @ts-ignore
        <AppInitializer />
      }
    </StoresProvider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
