import React from "react";
import { TimePicker } from "antd";
import moment from "moment";

import { ExcludeProps } from "../utils/typescript";
import { TimestampMilliseconds } from "../types/timestamp";

type AntdTimePickerFilteredProps = ExcludeProps<
  React.ComponentProps<typeof TimePicker>,
  "value" | "onChange"
>;

type Props = AntdTimePickerFilteredProps & {
  value?: TimestampMilliseconds;
  onChange?: (value?: TimestampMilliseconds) => any;
};

export default React.forwardRef(
  ({ value, onChange, ...restProps }: Props, ref: React.Ref<any>) => {
    return (
      <TimePicker
        {...restProps}
        onChange={momentTime => {
          onChange && onChange(momentTime ? momentTime.valueOf() : undefined);
        }}
        ref={ref}
        value={value ? moment(value) : undefined}
        format={"HH:mm"}
      />
    );
  }
);
