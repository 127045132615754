import React, { useCallback, useEffect, useState } from "react";
import { Input } from "antd";
import QRCode from "qrcode.react";
import _ from "lodash";

type NumberInputProps = React.ComponentProps<typeof Input> & {
  onChange: (value?: number) => any;
};

const NumberInput = React.forwardRef(
  (
    { onChange, value, min, max, ...restProps }: NumberInputProps,
    ref: React.Ref<any>
  ) => {
    const [innerValue, setInnerValue] = useState(value);
    useEffect(() => {
      if (value == null || value === "undefined") {
        setInnerValue("");
      } else {
        setInnerValue(value);
      }
    }, [setInnerValue, value]);
    const handleChange = useCallback(
      event => {
        const { value } = event.target;
        const stringValue = value != null ? String(value).trim() : "";
        const numberRegex = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if (
          (!Number.isNaN(Number(stringValue)) &&
            numberRegex.test(stringValue)) ||
          stringValue === "" ||
          stringValue === "-"
        ) {
          const numberValue =
            stringValue && !isNaN(Number(stringValue))
              ? Number(stringValue)
              : undefined;
          let isValidNumber = true;
          if (numberValue != null && min != null && numberValue < min) {
            isValidNumber = false;
          } else if (numberValue != null && max != null && numberValue > max) {
            isValidNumber = false;
          }
          if (isValidNumber && onChange) {
            setInnerValue(prevInnerValue => {
              if (Number(prevInnerValue) !== numberValue) {
                onChange(numberValue);
              }
              return stringValue;
            });
          }
        }
      },
      [onChange, min, max, setInnerValue]
    );
    return (
      <Input
        {...restProps}
        ref={ref}
        value={innerValue}
        onChange={handleChange}
        placeholder="Input a number"
        maxLength={25}
      />
    );
  }
);

type InputProps = React.ComponentProps<typeof Input> &
  React.ComponentProps<typeof Input.Password> &
  React.ComponentProps<typeof Input.TextArea> & {
    type?: "password" | "text" | "textarea" | "number" | "qrcode";
    onChange?: (value: any) => any;
  };

export default React.forwardRef(
  ({ type, onChange, ...restProps }: InputProps, ref: React.Ref<any>) => {
    const handleChange = useCallback(
      (event: any) => {
        const eventTarget =
          _.get(event, "currentTarget") || _.get(event, "target") || null;
        let value = event;
        if (eventTarget != null) {
          value =
            eventTarget.type === "checkbox"
              ? eventTarget.checked
              : eventTarget.value;
        }
        onChange && onChange(value);
      },
      [onChange]
    );
    switch (type) {
      case "number":
        return <NumberInput {...restProps} ref={ref} onChange={handleChange} />;
      case "password":
        return (
          <Input.Password {...restProps} ref={ref} onChange={handleChange} />
        );
      case "textarea":
        return (
          <Input.TextArea
            {...restProps}
            ref={ref}
            onChange={handleChange}
            autosize={true}
          />
        );
      default:
        return (
          <React.Fragment>
            <Input {...restProps} ref={ref} onChange={handleChange} />
            {type === "qrcode" && restProps.value && (
              <div style={{ marginTop: "1em" }}>
                <QRCode value={String(restProps.value)} />
              </div>
            )}
          </React.Fragment>
        );
    }
  }
);
