export default {
  components: {
    form: {
      leavingDirtyFormWarning:
        "Hai modificato delle informazioni non ancora salvate, sei sicuro di procedere?"
    },
    transferList: {
      availableOptions: "Opzioni disponibili",
      selectedOptions: "Opzioni scelte"
    }
  },
  screens: {
    notFound: {
      resultDescription: "Sorry, the page you visited does not exist.",
      homeButton: "Home"
    }
  }
};
