import React from "react";
import {
  XYPlot,
  XAxis,
  //   YAxis,
  DiscreteColorLegend,
  VerticalGridLines,
  HorizontalGridLines,
  HorizontalBarSeries,
  LabelSeries
  // @ts-ignore
} from "react-vis";
import _ from "lodash";

import useDimensions from "../utils/hooks/useDimensions";

export default ({ data }: any) => {
  const rangedArtworks = Object.keys(data.rangedArtworks).map(id => ({
    y: _.get(data, `artworks.${id}.name.it`, ""),
    x: data.rangedArtworks[id]
  }));
  const visitedArtworks = Object.keys(data.visitedArtworks).map(id => ({
    y: _.get(data, `artworks.${id}.name.it`, ""),
    x: data.visitedArtworks[id]
  }));
  const listenedArtworks = Object.keys(data.listenedArtworks).map(id => ({
    y: _.get(data, `artworks.${id}.name.it`, ""),
    x: data.listenedArtworks[id]
  }));

  const labelData = _.uniqBy(
    [...rangedArtworks, ...visitedArtworks, ...listenedArtworks],
    "y"
  ).map(d => ({
    y: d.y,
    x: _.max([
      0,
      _.get(_.find(rangedArtworks, a => a.y === d.y), "x", 0),
      _.get(_.find(visitedArtworks, a => a.y === d.y), "x", 0),
      _.get(_.find(listenedArtworks, a => a.y === d.y), "x", 0)
    ])
  }));

  const [ref, { width }] = useDimensions();

  return (
    <div
      ref={ref}
      style={{ position: "relative", paddingTop: "60px", width: "100%" }}
    >
      <XYPlot
        yType="ordinal"
        width={width}
        height={100 + labelData.length * 50}
      >
        <DiscreteColorLegend
          style={{ position: "absolute", left: "40px", top: "0px" }}
          orientation="horizontal"
          items={[
            {
              title: " Persone entrate nelle vicinanze",
              color: "#12939A"
            },
            {
              title: " Persone che hanno visitato il punto",
              color: "#79C7E3"
            },
            {
              title: " Persone che hanno ascoltato l'audioguida",
              color: "#1A3177"
            }
          ]}
        />
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis
          tickFormat={
            // Do not show ticks with decimals
            (value: number) => (Math.round(value) === value ? value : "")
          }
        />
        {/* <YAxis /> */}
        <HorizontalBarSeries data={rangedArtworks} />
        <HorizontalBarSeries data={visitedArtworks} />
        <HorizontalBarSeries data={listenedArtworks} />
        <LabelSeries data={labelData} getLabel={(d: any) => d.y} />
      </XYPlot>
    </div>
  );
};
