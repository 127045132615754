import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect, Route, Switch } from "react-router-dom";

import navigationUtils from "./utils/navigation";
import Breadcrumb from "./components/Breadcrumb";
import Button from "./components/Button";
import Layout from "./components/Layout";
import Text from "./components/Text";
import ArtworkDetailScreen from "./screens/ArtworkDetail";
import ArtworkListScreen from "./screens/ArtworkList";
import MuseumDetailScreen from "./screens/MuseumDetail";
import MuseumListScreen from "./screens/MuseumList";
import MuseumAreaDetailScreen from "./screens/MuseumAreaDetail";
import MuseumAreaListScreen from "./screens/MuseumAreaList";
import MuseumRouteDetailScreen from "./screens/MuseumRouteDetail";
import MuseumRouteTagDetailScreen from "./screens/MuseumRouteTagDetail";
import MuseumRouteListScreen from "./screens/MuseumRouteList";
import MuseumAnalyticsScreen from "./screens/MuseumAnalytics";
import MuseumSurveyScreen from "./screens/MuseumSurvey";
import NotFoundScreen from "./screens/NotFound";
import TenantDetailScreen from "./screens/TenantDetail";
import TenantListScreen from "./screens/TenantList";
import UserDetailScreen from "./screens/UserDetail";
import UserListScreen from "./screens/UserList";
import Sidebar from "./Sidebar";
import { Stores } from "./types/stores";
import { User } from "./types/user";

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  breadcrumbRoutes: string[];
  currentUser: User | null;
  logout: () => Promise<void>;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const userId = navigationUtils.fromRoutes.userId(ownProps.location.pathname);
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const museumId = navigationUtils.fromRoutes.museumId(
    ownProps.location.pathname
  );
  const artworkId = navigationUtils.fromRoutes.artworkId(
    ownProps.location.pathname
  );
  const museumRouteId = navigationUtils.fromRoutes.museumRouteId(
    ownProps.location.pathname
  );
  const museumRouteTagId = navigationUtils.fromRoutes.museumRouteTagId(
    ownProps.location.pathname
  );
  const isHomePage =
    ownProps.location.pathname === navigationUtils.routes.home.main();
  let breadcrumbRoutes = isHomePage ? ["Home"] : [];
  if (userId) {
    breadcrumbRoutes.push("Utente");
  } else if (ownProps.location.pathname.includes("users")) {
    breadcrumbRoutes.push("Lista utenti");
  }
  if (tenantId) {
    breadcrumbRoutes.push("Cliente");
  } else if (ownProps.location.pathname.includes("tenants")) {
    breadcrumbRoutes.push("Lista clienti");
  }
  if (museumId) {
    breadcrumbRoutes.push("Museo");
  } else if (ownProps.location.pathname.includes("museums")) {
    breadcrumbRoutes.push("Lista musei");
  }
  if (artworkId) {
    breadcrumbRoutes.push("Contenuto");
  } else if (ownProps.location.pathname.includes("artworks")) {
    breadcrumbRoutes.push("Lista contenuti");
  }
  if (museumRouteId) {
    breadcrumbRoutes.push("Itinerario");
  } else if (ownProps.location.pathname.includes("routes")) {
    breadcrumbRoutes.push("Lista itinerari");
  }
  if (museumRouteTagId) {
    breadcrumbRoutes.push("Tipologia itinerario");
  } else if (ownProps.location.pathname.includes("routeTags")) {
    breadcrumbRoutes.push("Lista tipologie itinerari");
  }
  if (ownProps.location.pathname.includes("analytics"))
    breadcrumbRoutes.push("Analytics");
  if (ownProps.location.pathname.includes("survey"))
    breadcrumbRoutes.push("Sondaggio");
  return {
    breadcrumbRoutes,
    currentUser: stores.auth.user,
    logout: stores.auth.logout
  };
};

class App extends React.Component<OwnProps & StoresProps> {
  render() {
    const { breadcrumbRoutes, currentUser, logout } = this.props;

    return (
      <Layout style={{ height: "100%" }}>
        <Layout.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }}
        >
          {currentUser && (
            <Text style={{ marginRight: "1em", color: "white" }}>
              {currentUser.name} {currentUser.surname}
            </Text>
          )}
          <Button onClick={logout}>Logout</Button>
        </Layout.Header>
        <Layout style={{ height: "100%" }}>
          <Layout.Sider collapsible>
            {
              // @ts-ignore
              <Sidebar />
            }
          </Layout.Sider>
          <Layout style={{ height: "100%", padding: "1em" }}>
            <Breadcrumb style={{ marginBottom: "1em" }}>
              {breadcrumbRoutes.map((route, index) => (
                <Breadcrumb.Item key={`${index}_${route}`}>
                  {route}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
            <Layout.Content>
              <Switch>
                <Route
                  path={navigationUtils.routes.notFound.main()}
                  exact={true}
                  component={NotFoundScreen}
                />
                <Route
                  path={navigationUtils.routes.user.find(":userId")}
                  exact={true}
                  component={UserDetailScreen}
                />
                <Route
                  path={navigationUtils.routes.user.list()}
                  exact={true}
                  component={UserListScreen}
                />
                <Route
                  path={navigationUtils.routes.artwork.find(
                    ":tenantId",
                    ":museumId",
                    ":artworkId"
                  )}
                  exact={true}
                  component={ArtworkDetailScreen}
                />
                <Route
                  path={navigationUtils.routes.artwork.list(
                    ":tenantId",
                    ":museumId"
                  )}
                  exact={true}
                  component={ArtworkListScreen}
                />
                <Route
                  path={navigationUtils.routes.museum.area.find(
                    ":tenantId",
                    ":museumId",
                    ":areaId"
                  )}
                  exact={true}
                  component={MuseumAreaDetailScreen}
                />
                <Route
                  path={navigationUtils.routes.museum.area.list(
                    ":tenantId",
                    ":museumId"
                  )}
                  exact={true}
                  component={MuseumAreaListScreen}
                />
                <Route
                  path={navigationUtils.routes.museum.route.find(
                    ":tenantId",
                    ":museumId",
                    ":routeId"
                  )}
                  exact={true}
                  component={MuseumRouteDetailScreen}
                />
                <Route
                  path={navigationUtils.routes.museum.routeTag.find(
                    ":tenantId",
                    ":museumId",
                    ":routeTagId"
                  )}
                  exact={true}
                  component={MuseumRouteTagDetailScreen}
                />
                <Route
                  path={navigationUtils.routes.museum.route.list(
                    ":tenantId",
                    ":museumId"
                  )}
                  exact={true}
                  component={MuseumRouteListScreen}
                />
                <Route
                  path={navigationUtils.routes.analytics.survey(
                    ":tenantId",
                    ":museumId"
                  )}
                  exact={true}
                  component={MuseumSurveyScreen}
                />
                <Route
                  path={navigationUtils.routes.analytics.main(
                    ":tenantId",
                    ":museumId"
                  )}
                  exact={true}
                  component={MuseumAnalyticsScreen}
                />
                <Route
                  path={navigationUtils.routes.museum.find(
                    ":tenantId",
                    ":museumId"
                  )}
                  exact={true}
                  component={MuseumDetailScreen}
                />
                <Route
                  path={navigationUtils.routes.museum.list()}
                  exact={true}
                  component={MuseumListScreen}
                />
                <Route
                  path={navigationUtils.routes.tenant.find(":tenantId")}
                  exact={true}
                  component={TenantDetailScreen}
                />
                <Route
                  path={navigationUtils.routes.tenant.list()}
                  exact={true}
                  component={TenantListScreen}
                />
                <Redirect to={navigationUtils.routes.notFound.main()} />
              </Switch>
            </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default inject(mapStoresToProps)(observer(App));
