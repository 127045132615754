import { action, observable, computed } from "mobx";
import _ from "lodash";

import loggerService from "../services/logger";
import parseService from "../services/parse";
import AuthStore from "./Auth";

import { Artwork } from "../types/artwork";
import { Museum } from "../types/museum";

export default class ArtworksStore {
  @observable
  public isInitialized: boolean = false;
  @observable
  public isFetchingArtworks: boolean = false;
  @observable
  public isCreatingArtworks: boolean = false;
  @observable
  public isUpdatingArtworks: boolean = false;
  @observable
  public isDeletingArtworks: boolean = false;
  @observable
  public isGeneratingAudio: boolean = false;
  @observable
  public artworks: { [artworkId: string]: Artwork } | null = null;
  @observable
  public selectedArtworkId: Artwork["objectId"] | null = null;
  public authStore: AuthStore;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

  @action
  public initialize = async () => {
    this.isInitialized = true;
  };

  @action
  public fetchArtworks = async () => {
    let artworks: Artwork[] = [];
    this.isFetchingArtworks = true;
    try {
      artworks = await parseService.fetchArtworks();
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    this.artworks = _.keyBy(artworks, "objectId");
    this.isFetchingArtworks = false;
  };

  @action
  public createArtwork = async (
    params: Partial<Artwork>,
    museumId: Museum["objectId"]
  ): Promise<Artwork | null> => {
    this.isCreatingArtworks = true;
    let createdArtwork: Artwork | null = null;
    try {
      createdArtwork = await parseService.createArtworkForMuseum(
        params,
        museumId
      );
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.artworks) {
      this.artworks = {};
    }
    if (createdArtwork) {
      this.artworks[createdArtwork.objectId] = createdArtwork;
    }
    this.isCreatingArtworks = false;
    return createdArtwork;
  };

  @action
  public updateArtwork = async (
    params: Partial<Artwork>
  ): Promise<Artwork | null> => {
    if (!params.objectId) {
      return null;
    }
    this.isUpdatingArtworks = true;
    let updatedArtwork: Artwork | null = null;
    try {
      updatedArtwork = await parseService.updateArtwork(params);
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.artworks) {
      this.artworks = {};
    }
    if (!updatedArtwork) {
      delete this.artworks[params.objectId];
    } else {
      this.artworks[params.objectId] = updatedArtwork;
    }
    this.isUpdatingArtworks = false;
    return updatedArtwork;
  };

  @action
  public deleteArtwork = async (artworkId: Artwork["objectId"]) => {
    let success = false;
    this.isDeletingArtworks = true;
    try {
      await parseService.deleteArtwork(artworkId);
      success = true;
    } catch (err) {
      if (parseService.isSessionError(err)) {
        this.authStore.destroy();
      } else {
        loggerService.warning(err.message);
      }
    }
    if (!this.artworks) {
      this.artworks = {};
    }
    if (success) {
      delete this.artworks[artworkId];
    }
    this.isDeletingArtworks = false;
    return success;
  };

  @action
  public generateMainAudioForArtwork = async (
    artworkId: Artwork["objectId"],
    languageCode: string
  ) => {
    if (this.isGeneratingAudio) {
      return false;
    }
    this.isGeneratingAudio = true;
    const success = await parseService.requestAudioGeneration(
      "Artwork",
      artworkId,
      languageCode
    );
    if (success) {
      await this.fetchArtworks();
    }
    this.isGeneratingAudio = false;
    return success;
  };

  @computed
  get selectedArtwork(): Artwork | null {
    if (!this.selectedArtworkId || !this.artworks) {
      return null;
    }
    return this.artworks[this.selectedArtworkId] || null;
  }
}
