import React, { useCallback } from "react";
import { Tree } from "antd";

export interface TreeOption {
  title: string;
  key: string;
  children?: TreeOption[];
}

interface Props {
  options?: TreeOption[];
  checkedOptionKeys?: string[];
  onCheckOption?: (checkedOptionKeys: string[]) => any;
}

const TreeSelect = ({
  options = [],
  checkedOptionKeys,
  onCheckOption
}: Props) => {
  const renderTreeNodes = useCallback(
    (options: TreeOption[]) =>
      options.map(option => {
        if (option.children) {
          return (
            <Tree.TreeNode
              title={option.title}
              key={option.key}
              dataRef={option}
            >
              {renderTreeNodes(option.children)}
            </Tree.TreeNode>
          );
        }
        return <Tree.TreeNode {...option} />;
      }),
    []
  );

  return (
    <Tree
      checkable={true}
      defaultExpandAll={true}
      checkedKeys={checkedOptionKeys}
      // @ts-ignore
      onCheck={onCheckOption}
    >
      {renderTreeNodes(options)}
    </Tree>
  );
};

export default TreeSelect;
