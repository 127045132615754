import React, { useCallback, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import uiUtils from "../utils/ui";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Image from "../components/Image";
import Layout from "../components/Layout";
import Link from "../components/Link";
import PageHeader from "../components/PageHeader";
import Popconfirm from "../components/Popconfirm";
import Spinner from "../components/Spinner";
import Table from "../components/Table";
import { Stores } from "../types/stores";
import { Museum, MuseumArea } from "../types/museum";

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  tenantId: string | null;
  museumId: string | null;
  museum: Museum | null;
  fetchMuseums: () => Promise<void>;
  deleteMuseumArea: (
    id: MuseumArea["id"],
    museumId: Museum["objectId"]
  ) => Promise<boolean>;
  isLoading: boolean;
  canCreateMuseumAreas: boolean;
  canDeleteMuseumAreas: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const museumId = navigationUtils.fromRoutes.museumId(
    ownProps.location.pathname
  );
  const museum = (museumId && _.get(stores.museums.museums, museumId)) || null;
  const currentUserId = _.get(stores.auth.user, "objectId");
  const canCreateMuseumAreas =
    stores.users.getIsAdmin(currentUserId) ||
    stores.users.getIsTenantAdmin(currentUserId, tenantId) ||
    stores.users.getIsMuseumAdmin(currentUserId, museumId) ||
    stores.users.getIsContentAdmin(currentUserId, museumId);
  const canDeleteMuseumAreas = canCreateMuseumAreas;
  return {
    tenantId,
    museumId,
    museum,
    fetchMuseums: stores.museums.fetchMuseums,
    deleteMuseumArea: stores.museums.deleteMuseumArea,
    isLoading: stores.museums.isFetchingMuseums,
    canCreateMuseumAreas,
    canDeleteMuseumAreas
  };
};

const MuseumRouteListScreen = ({
  tenantId,
  museumId,
  fetchMuseums,
  deleteMuseumArea,
  museum,
  isLoading,
  canCreateMuseumAreas,
  canDeleteMuseumAreas
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchMuseums();
  }, [fetchMuseums]);

  const handleDeleteArea = useCallback(
    (areaId: string) => {
      if (!museumId) {
        return loggerService.error("Errore durante l'eliminazione");
      }
      deleteMuseumArea(areaId, museumId).then(() => {
        loggerService.success("Eliminazione avvenuta con successo");
      });
    },
    [deleteMuseumArea, museumId]
  );

  const renderAreaActionButtons = useCallback(
    (areaId: string) => {
      return (
        <div style={{ textAlign: "right" }}>
          {canDeleteMuseumAreas && (
            <Popconfirm
              placement="leftTop"
              title={"Sei sicuro di eliminarlo?"}
              onConfirm={() => handleDeleteArea(areaId)}
              okText="Procedi"
              cancelText="Torna indietro"
            >
              <Button>
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          )}
        </div>
      );
    },
    [handleDeleteArea, canDeleteMuseumAreas]
  );

  if (isLoading || !tenantId || !museumId || !museum) {
    return <Spinner />;
  }

  return (
    <Layout>
      <PageHeader
        title="Lista aree del museo"
        extra={
          canCreateMuseumAreas && (
            <Link
              to={navigationUtils.routes.museum.area.create(tenantId, museumId)}
            >
              <Button>{"Crea nuova area"}</Button>
            </Link>
          )
        }
      />
      <Table dataSource={museum.areas.map(r => ({ ...r, key: r.id }))}>
        <Table.Column
          title="Nome"
          dataIndex="name.it"
          key="name"
          sorter={uiUtils.getSortFunction<MuseumArea>("name.it")}
          render={(name, area) => (
            <Link
              to={navigationUtils.routes.museum.area.find(
                tenantId,
                museumId,
                area.id
              )}
            >
              {name}
            </Link>
          )}
        />
        <Table.Column
          title="Immagine di copertina"
          dataIndex="coverImage"
          key="coverImage"
          sorter={uiUtils.getSortFunction<MuseumArea>("coverImage")}
          render={coverImage =>
            coverImage &&
            coverImage.url && (
              <div style={{ maxWidth: "8em" }}>
                <Image src={coverImage.url} alt={"coverImage"} />
              </div>
            )
          }
        />
        <Table.Column
          dataIndex="id"
          key="id"
          render={renderAreaActionButtons}
        />
      </Table>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(MuseumRouteListScreen));
