import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import objectUtils from "../utils/object";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Input from "../components/Input";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Spinner from "../components/Spinner";
import Tabs from "../components/Tabs";
import Upload from "../components/Upload";
import { Language } from "../types/language";
import { Museum, MuseumArea } from "../types/museum";
import { Stores } from "../types/stores";

const getFormValues = (area: MuseumArea | null) => {
  return {
    ..._.omit(area, [])
  };
};

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  tenantId: string | null;
  museumId: string | null;
  museum: Museum | null;
  museumAreaId: string | null;
  museumArea: MuseumArea | null;
  isCreatingMuseumArea: boolean;
  languages: Language[];
  createMuseumArea: (
    params: Partial<MuseumArea>,
    museumId: Museum["objectId"]
  ) => Promise<MuseumArea | null>;
  updateMuseumArea: (
    params: Partial<MuseumArea>,
    museumId: Museum["objectId"]
  ) => Promise<MuseumArea | null>;
  fetchMuseums: () => Promise<void>;
  isLoading: boolean;
  canSaveMuseumAreas: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const museumId = navigationUtils.fromRoutes.museumId(
    ownProps.location.pathname
  );
  const museumAreaId = navigationUtils.fromRoutes.museumAreaId(
    ownProps.location.pathname
  );
  const museum = (museumId && _.get(stores.museums.museums, museumId)) || null;
  const languages: Language[] = _.get(museum, `languages`, []);
  const museumAreas = _.get(museum, "areas", []);
  const museumArea = _.find(museumAreas, r => r.id === museumAreaId) || null;
  const isCreatingMuseumArea =
    museumAreaId === navigationUtils.routesUtils.pathCreate;
  const currentUserId = _.get(stores.auth.user, "objectId");
  const canSaveMuseumAreas =
    stores.users.getIsAdmin(currentUserId) ||
    stores.users.getIsTenantAdmin(currentUserId, tenantId) ||
    stores.users.getIsMuseumAdmin(currentUserId, museumId) ||
    stores.users.getIsContentAdmin(currentUserId, museumId);
  return {
    tenantId,
    museumId,
    museum,
    museumAreaId,
    museumArea,
    isCreatingMuseumArea,
    languages,
    createMuseumArea: stores.museums.createMuseumArea,
    updateMuseumArea: stores.museums.updateMuseumArea,
    fetchMuseums: stores.museums.fetchMuseums,
    isLoading:
      stores.museums.isFetchingMuseums || stores.artworks.isFetchingArtworks,
    canSaveMuseumAreas
  };
};

const MuseumAreaDetailScreen = ({
  history,
  location,
  tenantId,
  museumId,
  museum,
  museumAreaId,
  museumArea,
  isCreatingMuseumArea,
  languages,
  createMuseumArea,
  updateMuseumArea,
  fetchMuseums,
  isLoading,
  canSaveMuseumAreas
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchMuseums();
  }, [fetchMuseums]);

  const [formValues, setFormValues] = useState(getFormValues(museumArea));

  useEffect(() => {
    setFormValues(getFormValues(museumArea));
  }, [setFormValues, museumArea]);

  const handleValidSubmit = useCallback(
    (values: any) => {
      if (!canSaveMuseumAreas) {
        return loggerService.error("Salvataggio non autorizzato");
      }
      if (!tenantId || !museumId) {
        return loggerService.error("Errore nel salvataggio");
      }
      if (!isCreatingMuseumArea && !museumArea) {
        return loggerService.error("Errore nel salvataggio");
      }
      const handleSaveMuseumArea = async () => {
        try {
          const params: any = objectUtils.pickOrUndefined(values, [
            "name",
            "coverImage",
            "mapImage"
          ]);
          if (museumArea) {
            params.id = museumArea.id;
          }
          let savedMuseumArea: MuseumArea | null = null;
          if (isCreatingMuseumArea) {
            // @ts-ignore
            savedMuseumArea = await createMuseumArea(params, museumId);
          } else {
            savedMuseumArea = await updateMuseumArea(params, museumId);
          }
          if (!savedMuseumArea) {
            return loggerService.error("Errore nel salvataggio");
          }
          loggerService.success("Salvataggio riuscito");
          navigationUtils.goTo(
            { history, location },
            navigationUtils.routes.museum.area.find(
              tenantId,
              museumId,
              savedMuseumArea.id
            )
          );
        } catch (err) {
          loggerService.error("Errore nel salvataggio");
        }
      };
      return handleSaveMuseumArea();
    },
    [
      canSaveMuseumAreas,
      isCreatingMuseumArea,
      museumArea,
      createMuseumArea,
      updateMuseumArea,
      museumId,
      tenantId,
      history,
      location
    ]
  );

  const [selectedLanguage, setSelectedLanguage] = useState("it");

  if (
    isLoading ||
    !tenantId ||
    !museumId ||
    !museum ||
    !museumAreaId ||
    (!museumArea && !isCreatingMuseumArea)
  ) {
    return <Spinner />;
  }

  const firstAvailableLanguage: Language | undefined = _.get(
    languages,
    "0",
    undefined
  );
  const pageTitle = isCreatingMuseumArea
    ? "Crea nuova area del museo"
    : _.get(museumArea, `name.it`) ||
      (firstAvailableLanguage &&
        _.get(museumArea, `name.${firstAvailableLanguage}`)) ||
      "Modifica area del museo";

  return (
    <Layout style={{ height: "100%" }}>
      <PageHeader title={pageTitle} />
      <Form
        key={`${formValues.id}`}
        initialValues={formValues}
        onSubmit={handleValidSubmit}
        hasSubmitButton={canSaveMuseumAreas}
      >
        <Tabs defaultActiveKey="it" onChange={setSelectedLanguage}>
          {languages.map(language => (
            <Tabs.TabPane tab={language} key={language} />
          ))}
        </Tabs>
        <FormField
          name={"name"}
          label="Nome"
          as={Input}
          type="text"
          required
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name="coverImage"
          label="Immagine di copertina"
          as={Upload}
          mimeTypes={["image/jpeg", "image/png"]}
          filesMaxCount={1}
        />
        <FormField
          name={"mapImage"}
          label="Immagine in lingua"
          as={Upload}
          mimeTypes={["image/jpeg", "image/png"]}
          filesMaxCount={1}
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
      </Form>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(MuseumAreaDetailScreen));
