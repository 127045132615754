import React, { useState } from "react";
import styled from "styled-components";
import _ from "lodash";

import { ArtworkMultimedia } from "../types/artwork";
import parseService from "../services/parse";
import loggerService from "../services/logger";

import { ParseFile } from "../types/parse";
import { Language } from "../types/language";

import Icon from "./Icon";
import Modal from "./Modal";
import Popconfirm from "./Popconfirm";
import Spinner from "./Spinner";
import FilePreview from "./File";
import Input from "./Input";
import Title from "./Title";
import Text from "./Text";

type RemoteFile = ArtworkMultimedia;

type Props = {
  value?: RemoteFile[];
  onChange: (value?: RemoteFile | RemoteFile[]) => void;
  filesMaxCount?: number;
  filesMaxSize?: number; // Max allowed file size in MB
  mimeTypes?: string[];
  language: Language;
  onError?: (message: string) => void;
};

export const UploadMultimedia: React.FC<Props> = ({
  value: files,
  onChange,
  onError = loggerService.error,
  filesMaxCount,
  filesMaxSize = 50, // MB
  mimeTypes,
  language
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [fileToPreview, setFileToPreview] = useState<RemoteFile>();

  const handleOnChange = (newFiles?: RemoteFile[]) => {
    if (!newFiles) {
      onChange(undefined);
    } else if (filesMaxCount === 1) {
      onChange(newFiles[0]);
    } else {
      onChange(newFiles);
    }
  };

  const removeFile = (file: RemoteFile) => {
    const filesWithoutRemoved = files?.filter(
      f =>
        getLocaleFile(f, language).name !== getLocaleFile(file, language).name
    );
    handleOnChange(filesWithoutRemoved);
  };

  const changeFileDescription = (file: RemoteFile, description?: string) => {
    setFileToPreview(
      _.set(_.cloneDeep(file), `description.${language}`, description)
    );
  };

  const closeFilePreview = () => {
    if (fileToPreview) {
      handleOnChange(
        files?.map(f =>
          getLocaleFile(f, language).name !==
          getLocaleFile(fileToPreview, language).name
            ? f
            : fileToPreview
        )
      );
    }
    setFileToPreview(undefined);
  };

  const uploadFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = Array.from(event.target.files || []);
    const hasInvalidFileTypes = uploadedFiles.some(
      file => !getIsValidFileType(file, mimeTypes)
    );
    const hasInvalidFileSizes = uploadedFiles.some(
      file => !getIsValidFileSize(file, filesMaxSize)
    );
    if (mimeTypes && hasInvalidFileTypes && onError) {
      onError(
        `I tipi di file accettati per questo upload sono: ${mimeTypes.join(
          ", "
        )}`
      );
    }
    if (filesMaxSize && hasInvalidFileSizes && onError) {
      onError(
        `È possibile eseguire upload di file con dimensione massima di ${filesMaxSize}MB`
      );
    }
    if (hasInvalidFileTypes || hasInvalidFileSizes) {
      return;
    }
    setIsUploading(true);
    try {
      const parseUploadedFiles = await uploadFilesToParse(uploadedFiles);
      let newFileList = files
        ? files.concat(parseUploadedFiles)
        : parseUploadedFiles;
      if (filesMaxCount != null) {
        newFileList = newFileList.slice(0, filesMaxCount);
      }
      handleOnChange(newFileList);
    } catch (err) {
      onError(err.message);
    }
    setIsUploading(false);
  };

  const isUploadButtonVisible =
    filesMaxCount == null || !files || files.length < filesMaxCount;

  return (
    <UploadContainer>
      {files?.map(file => (
        <UploadedMultimedia
          key={getLocaleFile(file, language).name}
          language={language}
          file={file}
          onRemove={() => removeFile(file)}
          onOpen={() => setFileToPreview(file)}
        />
      ))}

      {isUploadButtonVisible && (
        <UploadButton>
          {isUploading ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <Icon type="plus" />
              <div>Upload</div>
            </React.Fragment>
          )}
          <input
            multiple={filesMaxCount !== 1}
            type="file"
            onChange={uploadFiles}
            disabled={isUploading}
          />
        </UploadButton>
      )}

      <Modal
        visible={!!fileToPreview}
        footer={null}
        onCancel={closeFilePreview}
      >
        {fileToPreview && (
          <>
            <FilePreview src={getLocaleFile(fileToPreview, language).url} />
            <br />
            <Title level={4}>Didascalia</Title>
            <Input
              type="textarea"
              value={fileToPreview.description?.[language]}
              placeholder="Breve descrizione (opzionale)"
              onChange={(value: any) =>
                changeFileDescription(fileToPreview, value)
              }
            />
          </>
        )}
      </Modal>
    </UploadContainer>
  );
};

const UploadedMultimedia: React.FC<{
  file: RemoteFile;
  language: Language;
  loading?: boolean;
  onOpen: () => void;
  onRemove: () => void;
}> = ({ file, loading, language, onRemove, onOpen }) => {
  const isMultilanguageMultimedia = language in file.file;
  const isMultimediaWithThumbnail = "thumbnail" in file;
  const canEdit = !isMultilanguageMultimedia && !isMultimediaWithThumbnail;

  return (
    <UploadItem>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <FilePreview src={getLocaleFile(file, language).url} />
          <UploadItemOverlay>
            {canEdit && (
              <>
                <Icon
                  style={{ fontSize: "2em", color: "white" }}
                  type="eye"
                  onClick={!isMultilanguageMultimedia ? onOpen : undefined}
                />
                <Popconfirm
                  placement="leftTop"
                  title={"Sei sicuro di eliminarlo?"}
                  onConfirm={onRemove}
                  disabled={isMultilanguageMultimedia}
                  okText="Procedi"
                  cancelText="Torna indietro"
                >
                  <Icon
                    style={{ fontSize: "2em", color: "white" }}
                    type="delete"
                  />
                </Popconfirm>
              </>
            )}
            {isMultilanguageMultimedia && (
              <Text style={{ color: "white" }}>
                Modifiche a multimedia multilingua non ancora supportate
              </Text>
            )}
            {isMultimediaWithThumbnail && (
              <Text style={{ color: "white" }}>
                Modifiche a multimedia con thumbnail non ancora supportate
              </Text>
            )}
          </UploadItemOverlay>
        </>
      )}
    </UploadItem>
  );
};

const uploadFilesToParse = async (files: File[]): Promise<RemoteFile[]> => {
  const uploadedFiles = await Promise.all(
    files.map(file =>
      parseService.uploadFile(file.name, file).catch(() => undefined)
    )
  );
  return uploadedFiles
    .filter((file): file is ParseFile => file != null)
    .map(file => ({
      file: file
    }));
};

const getIsValidFileType = (file: File, mimeTypes?: string[]) => {
  if (!mimeTypes) {
    return true;
  }
  return mimeTypes.map(mime => mime.toLowerCase()).indexOf(file.type) !== -1;
};

const getIsValidFileSize = (file: File, filesMaxSize?: number) => {
  if (!filesMaxSize) {
    return true;
  }
  return file.size / 1024 / 1024 <= filesMaxSize;
};

const getLocaleFile = (file: RemoteFile, lang: Language): ParseFile => {
  if ("name" in file.file) {
    return file.file;
  }
  return file.file[lang];
};

const UploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const UploadItem = styled.div`
  position: relative;
  background: white;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12em;
  height: 12em;
  box-shadow: 0px 0px 0.5em rgba(0, 0, 0, 0.3);
  margin: 0.5em;
`;

const UploadButton = styled.label`
  position: relative;
  background: white;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12em;
  height: 12em;
  box-shadow: 0px 0px 0.5em rgba(0, 0, 0, 0.3);
  margin: 0.5em;
  input {
    height: 0;
    width: 0;
    display: none;
  }
`;

const UploadItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  &:hover {
    opacity: 1;
  }
`;
