import React from "react";
import styled from "styled-components";

import Icon from "./Icon";
import Divider from "./Divider";
import Title from "./Title";

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledExtra = styled.div`
  margin-left: auto;
`;

interface Props {
  title: string;
  extra?: React.ReactNode;
  onBack?: () => any;
}

const PageHeader = ({ title, onBack, extra }: Props) => {
  return (
    <StyledHeader>
      {onBack && (
        <React.Fragment>
          <Icon
            type="arrow-left"
            onClick={onBack}
            style={{ fontSize: "1.5em" }}
          />
          <Divider type={"vertical"} />
        </React.Fragment>
      )}
      <Title>{title}</Title>
      {extra && <StyledExtra>{extra}</StyledExtra>}
    </StyledHeader>
  );
};

export default PageHeader;
