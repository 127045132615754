import styled from "styled-components";

const DescriptionContainer = styled.div`
  width: 100%;
`;

const DescriptionSectionContainer = styled.div`
  box-shadow: 0px 0px 0.5em rgba(0, 0, 0, 0.3);
  margin-bottom: 2em;
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export default {
  DescriptionContainer,
  DescriptionSectionContainer
};
