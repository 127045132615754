import React, { useEffect, useState } from "react";
import _ from "lodash";

import Checkbox from "./Checkbox";

interface Options {
  [optionName: string]: boolean;
}

interface Props {
  value?: Options;
  onChange?: (newValue: Options) => any;
  disabled?: boolean;
}

const Checklist: React.FC<Props> = ({ value, onChange, disabled }) => {
  const [innerValue, setInnerValue] = useState(value || {});
  useEffect(() => {
    setInnerValue(value || {});
  }, [value, setInnerValue]);
  const handleChange = (optionName: string, optionValue: boolean) => {
    const newValue = _.merge({}, innerValue, { [optionName]: optionValue });
    setInnerValue(newValue);
    onChange && onChange(newValue);
  };
  return (
    <div>
      {Object.keys(innerValue).map(optionName => (
        <Checkbox
          key={optionName}
          onChange={() => handleChange(optionName, !innerValue[optionName])}
          checked={!!innerValue[optionName]}
          disabled={disabled}
        >
          {optionName}
        </Checkbox>
      ))}
    </div>
  );
};

export default Checklist;
