import { Location, History } from "history";

const goTo = (
  { location, history }: { location: Location; history: History },
  destination: string
) => {
  location.pathname !== destination && history.push(destination);
};

const goBack = ({
  location,
  history
}: {
  location: Location;
  history: History;
}) => {
  history.goBack();
};

const openInNewTab = (
  { location, history }: { location: Location; history: History },
  destination: string
) => {
  const newTab = window.open(destination, "_blank");
  newTab && newTab.focus();
};

const reloadCurrentPage = () => {
  window && window.location.reload();
};

const routesUtils = {
  pathCreate: "new"
};

const fromRoutes = {
  userId: (pathname: string): string | null => {
    const userRegex = /\/users\/([^/?#]*)/i;
    const found = pathname.match(userRegex);
    return (found && found[1]) || null;
  },
  tenantId: (pathname: string): string | null => {
    const tenantRegex = /\/tenants\/([^/?#]*)/i;
    const found = pathname.match(tenantRegex);
    return (found && found[1]) || null;
  },
  museumId: (pathname: string): string | null => {
    const museumRegex = /\/museums\/([^/?#]*)/i;
    const found = pathname.match(museumRegex);
    return (found && found[1]) || null;
  },
  museumAreaId: (pathname: string): string | null => {
    const museumAreaRegex = /\/museums\/[^/]+\/areas\/([^/?#]*)/i;
    const found = pathname.match(museumAreaRegex);
    return (found && found[1]) || null;
  },
  museumRouteId: (pathname: string): string | null => {
    const museumRouteRegex = /\/museums\/[^/]+\/routes\/([^/?#]*)/i;
    const found = pathname.match(museumRouteRegex);
    return (found && found[1]) || null;
  },
  museumRouteTagId: (pathname: string): string | null => {
    const museumRouteTagRegex = /\/museums\/[^/]+\/routeTags\/([^/?#]*)/i;
    const found = pathname.match(museumRouteTagRegex);
    return (found && found[1]) || null;
  },
  artworkId: (pathname: string): string | null => {
    const artworkRegex = /\/artworks\/([^/?#]*)/i;
    const found = pathname.match(artworkRegex);
    return (found && found[1]) || null;
  }
};
const routes = {
  auth: {
    login: () => "/auth/login",
    signup: () => "/auth/signup",
    recover: () => "/auth/recover"
  },
  user: {
    list: () => `/users`,
    find: (userId: string | number) => `/users/${userId}`,
    create: () => `/users/${routesUtils.pathCreate}`
  },
  tenant: {
    list: () => `/tenants`,
    find: (tenantId: string | number) => `/tenants/${tenantId}`,
    create: () => `/tenants/${routesUtils.pathCreate}`
  },
  museum: {
    list: () => `/museums`,
    find: (tenantId: string | number, museumId: string | number) =>
      `/tenants/${tenantId}/museums/${museumId}`,
    create: (tenantId: string | number) =>
      `/tenants/${tenantId}/museums/${routesUtils.pathCreate}`,
    area: {
      list: (tenantId: string | number, museumId: string | number) =>
        `/tenants/${tenantId}/museums/${museumId}/areas`,
      find: (
        tenantId: string | number,
        museumId: string | number,
        areaId: string | number
      ) => `/tenants/${tenantId}/museums/${museumId}/areas/${areaId}`,
      create: (tenantId: string | number, museumId: string | number) =>
        `/tenants/${tenantId}/museums/${museumId}/areas/${
          routesUtils.pathCreate
        }`
    },
    route: {
      list: (tenantId: string | number, museumId: string | number) =>
        `/tenants/${tenantId}/museums/${museumId}/routes`,
      find: (
        tenantId: string | number,
        museumId: string | number,
        routeId: string | number
      ) => `/tenants/${tenantId}/museums/${museumId}/routes/${routeId}`,
      create: (tenantId: string | number, museumId: string | number) =>
        `/tenants/${tenantId}/museums/${museumId}/routes/${
          routesUtils.pathCreate
        }`
    },
    routeTag: {
      find: (
        tenantId: string | number,
        museumId: string | number,
        routeTagId: string | number
      ) => `/tenants/${tenantId}/museums/${museumId}/routeTags/${routeTagId}`,
      create: (tenantId: string | number, museumId: string | number) =>
        `/tenants/${tenantId}/museums/${museumId}/routeTags/${
          routesUtils.pathCreate
        }`
    }
  },
  artwork: {
    list: (tenantId: string | number, museumId: string | number) =>
      `/tenants/${tenantId}/museums/${museumId}/artworks`,
    find: (
      tenantId: string | number,
      museumId: string | number,
      artworkId: string | number
    ) => `/tenants/${tenantId}/museums/${museumId}/artworks/${artworkId}`,
    create: (tenantId: string | number, museumId: string | number) =>
      `/tenants/${tenantId}/museums/${museumId}/artworks/${
        routesUtils.pathCreate
      }`
  },
  analytics: {
    main: (tenantId: string | number, museumId: string | number) =>
      `/tenants/${tenantId}/museums/${museumId}/analytics`,
    survey: (tenantId: string | number, museumId: string | number) =>
      `/tenants/${tenantId}/museums/${museumId}/analytics/survey`
  },
  home: {
    main: () => `/`
  },
  notFound: {
    main: () => `/404`
  }
};

export default {
  reloadCurrentPage,
  goTo,
  goBack,
  openInNewTab,
  routes,
  fromRoutes,
  routesUtils
};
