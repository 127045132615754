import React from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import styled from "styled-components";

import TreeSelect, { TreeOption } from "./TreeSelect";

import { Museum } from "../types/museum";
import { Tenant } from "../types/tenant";
import { Stores } from "../types/stores";

interface OwnProps {
  value: string[]; // selectedRoles
  onChange: (selectedRoles: string[]) => any;
}

interface StoresProps {
  museums: Museum[];
  tenants: Tenant[];
}

const mapStoresToProps = (stores: Stores): StoresProps => {
  return {
    tenants: Object.values(stores.tenants.tenants || {}),
    museums: Object.values(stores.museums.museums || {})
  };
};

const UnorderedList = styled.ul`
  list-style-type: none;
  padding-left: 2em;
`;

const UnorderedListItem = styled.li``;

const UserRolesSelect = ({
  museums,
  tenants,
  value,
  onChange
}: OwnProps & StoresProps) => {
  const adminRoles = ["admin"];
  const adminSelectedRoles = value.filter(v => adminRoles.includes(v));
  const adminOptions: TreeOption[] = [
    {
      title: `Admin: gestione completa Amuse`,
      key: `admin`,
      children: []
    }
  ];
  const tenantsRoles = tenants.map(tenant => `tenantAdmin_${tenant.objectId}`);
  const tenantsSelectedRoles = value.filter(v => tenantsRoles.includes(v));
  const museumsRoles = _.flatten(
    museums.map(museum => [
      `museumAdmin_${museum.objectId}`,
      `contentAdmin_${museum.objectId}`,
      `statsAdmin_${museum.objectId}`
    ])
  );
  const museumsSelectedRoles = value.filter(v => museumsRoles.includes(v));
  const handleCheckAdminOption = (adminSelectedRoles: string[]) => {
    const newRoles = adminSelectedRoles.includes("admin")
      ? ["admin"]
      : [...tenantsSelectedRoles, ...museumsSelectedRoles];
    onChange(newRoles);
  };
  const handleCheckTenantOption = (
    tenantId: string,
    tenantSelectedRoles: string[]
  ) => {
    const newTenantsSelectedRoles = tenantsSelectedRoles
      .filter(role => !role.endsWith(tenantId))
      .concat(tenantSelectedRoles);
    const newMuseumsSelectedRoles = museumsSelectedRoles.filter(role =>
      museums.some(
        museum =>
          role.endsWith(museum.objectId) &&
          !newTenantsSelectedRoles.some(role =>
            role.endsWith(museum.tenant.objectId)
          )
      )
    );
    onChange([
      ...adminSelectedRoles,
      ...newTenantsSelectedRoles,
      ...newMuseumsSelectedRoles
    ]);
  };
  const handleCheckMuseumOption = (
    museumId: string,
    museumSelectedRoles: string[]
  ) => {
    onChange([
      ...adminSelectedRoles,
      ...tenantsSelectedRoles,
      ...museumsSelectedRoles.filter(role => !role.endsWith(museumId)),
      ...museumSelectedRoles
    ]);
  };

  return (
    <React.Fragment>
      <TreeSelect
        options={adminOptions}
        checkedOptionKeys={adminSelectedRoles}
        onCheckOption={handleCheckAdminOption}
      />
      <UnorderedList>
        {!adminSelectedRoles.includes("admin") &&
          tenants.map(tenant => {
            const tenantOptions: TreeOption[] = [
              {
                title: `Gestione Tenant ${tenant.name}`,
                key: `tenantAdmin_${tenant.objectId}`,
                children: []
              }
            ];
            return (
              <UnorderedListItem key={tenant.objectId}>
                <TreeSelect
                  options={tenantOptions}
                  checkedOptionKeys={tenantsSelectedRoles.filter(role =>
                    role.endsWith(tenant.objectId)
                  )}
                  onCheckOption={handleCheckTenantOption.bind(
                    null,
                    tenant.objectId
                  )}
                />
                <UnorderedList>
                  {!tenantsSelectedRoles.includes(
                    `tenantAdmin_${tenant.objectId}`
                  ) &&
                    museums
                      .filter(
                        museum => museum.tenant.objectId === tenant.objectId
                      )
                      .map(museum => {
                        const museumOptions: TreeOption[] = [
                          {
                            title: `Gestione Museo ${museum.name.it}`,
                            key: `museumAdmin_${museum.objectId}`,
                            children: [
                              {
                                title: `Gestione contenuti ${museum.name.it}`,
                                key: `contentAdmin_${museum.objectId}`,
                                children: []
                              },
                              {
                                title: `Gestione analytics ${museum.name.it}`,
                                key: `statsAdmin_${museum.objectId}`,
                                children: []
                              }
                            ]
                          }
                        ];
                        return (
                          <UnorderedListItem key={museum.objectId}>
                            <TreeSelect
                              options={museumOptions}
                              checkedOptionKeys={museumsSelectedRoles.filter(
                                role => role.endsWith(museum.objectId)
                              )}
                              onCheckOption={handleCheckMuseumOption.bind(
                                null,
                                museum.objectId
                              )}
                            />
                          </UnorderedListItem>
                        );
                      })}
                </UnorderedList>
              </UnorderedListItem>
            );
          })}
      </UnorderedList>
    </React.Fragment>
  );
};

export default inject(mapStoresToProps)(observer(UserRolesSelect));
