import React, { useCallback } from "react";
import { Formik, Form as FormikForm } from "formik";
import { Prompt } from "react-router-dom";
import _ from "lodash";

import stringsConfig from "../config/strings";
import FormField from "./FormField";
import Button from "./Button";

type FormProps = React.ComponentProps<typeof Formik> & {
  hasSubmitButton?: boolean;
  shouldPreventLeavingDirtyForm?: boolean;
  submitButtonLabel?: string;
};

export default ({
  children,
  hasSubmitButton = true,
  initialValues,
  onSubmit,
  validate,
  shouldPreventLeavingDirtyForm = false,
  submitButtonLabel = "Salva"
}: FormProps) => {
  const handleSubmit = useCallback(
    async (values, formikHelpers) => {
      if (onSubmit) {
        await onSubmit(values, formikHelpers);
      }
      formikHelpers.setSubmitting(false);
    },
    [onSubmit]
  );
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ dirty, isSubmitting, errors }) => (
        <FormikForm style={{ width: "100%" }}>
          {shouldPreventLeavingDirtyForm && (
            <Prompt
              when={dirty}
              message={stringsConfig.components.form.leavingDirtyFormWarning}
            />
          )}
          {children}
          {hasSubmitButton && (
            <FormField
              as={Button}
              type="primary"
              htmlType="submit"
              disabled={isSubmitting || !dirty || !_.isEmpty(errors)}
              loading={isSubmitting}
            >
              {submitButtonLabel}
            </FormField>
          )}
        </FormikForm>
      )}
    </Formik>
  );
};
