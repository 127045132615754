import React from "react";
import { Select } from "antd";

interface SelectOption {
  value: any;
  title: string;
}

type Props = React.ComponentProps<typeof Select> & {
  options: SelectOption[];
};

export default ({ options, value, onChange, ...restProps }: Props) => {
  return (
    <Select
      {...restProps}
      onChange={onChange}
      value={value}
      style={{ width: "100%" }}
    >
      {options.map((option, index) => (
        <Select.Option
          key={String(option.value || index)}
          // @ts-ignore
          value={option.value}
        >
          {option.title}
        </Select.Option>
      ))}
    </Select>
  );
};
