import styled from "styled-components";

const GeolocationTable = styled.table`
  box-shadow: 0px 0px 0.5em rgba(0, 0, 0, 0.3);
  border-collapse: separate;
  border-spacing: 1em 0.5em;
  margin: 0.5em 0;
`;

export default {
  GeolocationTable
};
