import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import navigationUtils from "../utils/navigation";
import ClusteredBarChart from "../components/ClusteredBarChart";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Spinner from "../components/Spinner";
import { Artwork } from "../types/artwork";
import { Stores } from "../types/stores";

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  tenantId: string | null;
  museumId: string | null;
  artworks: { [artworkId: string]: Artwork };
  fetchArtworks: () => Promise<void>;
  fetchStats: () => Promise<void>;
  rangedArtworks: { [artworkId: string]: number };
  visitedArtworks: { [artworkId: string]: number };
  listenedArtworks: { [artworkId: string]: number };
  isLoading: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const museumId = navigationUtils.fromRoutes.museumId(
    ownProps.location.pathname
  );
  return {
    tenantId,
    museumId,
    artworks: stores.artworks.artworks || {},
    fetchArtworks: stores.artworks.fetchArtworks,
    fetchStats: stores.surveys.fetchStats,
    // @ts-ignore
    rangedArtworks:
      _.get(stores.surveys.stats, `${museumId}.rangedArtworks`) || {},
    // @ts-ignore
    visitedArtworks:
      _.get(stores.surveys.stats, `${museumId}.visitedArtworks`) || {},
    // @ts-ignore
    listenedArtworks:
      _.get(stores.surveys.stats, `${museumId}.listenedArtworks`) || {},
    isLoading: stores.surveys.isFetchingStats
  };
};

const MuseumAnalyticsScreen = ({
  tenantId,
  museumId,
  artworks,
  fetchArtworks,
  fetchStats,
  isLoading,
  rangedArtworks,
  visitedArtworks,
  listenedArtworks
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchArtworks();
    fetchStats();
  }, [fetchArtworks, fetchStats]);
  if (isLoading || !tenantId || !museumId) {
    return <Spinner />;
  }

  return (
    <Layout style={{ height: "100%" }}>
      <PageHeader title="Analytics" />
      {Object.keys({
        ...rangedArtworks,
        ...visitedArtworks,
        ...listenedArtworks
      }).length > 0 ? (
        <ClusteredBarChart
          data={{ rangedArtworks, visitedArtworks, listenedArtworks, artworks }}
        />
      ) : (
        <p>Nessun dato disponibile</p>
      )}
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(MuseumAnalyticsScreen));
