import React, { useCallback, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import uiUtils from "../utils/ui";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Layout from "../components/Layout";
import Link from "../components/Link";
import PageHeader from "../components/PageHeader";
import Popconfirm from "../components/Popconfirm";
import Spinner from "../components/Spinner";
import Table from "../components/Table";
import { Stores } from "../types/stores";
import { Tenant } from "../types/tenant";

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  fetchTenants: () => Promise<void>;
  deleteTenant: (tenantId: Tenant["objectId"]) => Promise<boolean>;
  tenants: Tenant[];
  isLoading: boolean;
  canCreateTenants: boolean;
  canDeleteTenants: boolean;
}

const mapStoresToProps = (stores: Stores): StoresProps => {
  const currentUserId = _.get(stores.auth.user, "objectId");
  const canCreateTenants = stores.users.getIsAdmin(currentUserId);
  const canDeleteTenants = stores.users.getIsAdmin(currentUserId);
  return {
    fetchTenants: stores.tenants.fetchTenants,
    deleteTenant: stores.tenants.deleteTenant,
    tenants: _.values(stores.tenants.tenants),
    isLoading: stores.tenants.isFetchingTenants,
    canCreateTenants,
    canDeleteTenants
  };
};

const TenantListScreen = ({
  fetchTenants,
  deleteTenant,
  tenants,
  isLoading,
  canCreateTenants,
  canDeleteTenants
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchTenants();
  }, [fetchTenants]);

  const handleDeleteTenant = useCallback(
    (tenantId: string) => {
      deleteTenant(tenantId).then(success => {
        if (success) {
          loggerService.success("Eliminazione avvenuta con successo");
        } else {
          loggerService.success("Eliminazione non riuscita");
        }
      });
    },
    [deleteTenant]
  );

  const renderItemActionButtons = useCallback(
    (objectId: string) => {
      return (
        <div style={{ textAlign: "right" }}>
          {canDeleteTenants && (
            <Popconfirm
              placement="leftTop"
              title={"Sei sicuro di eliminarlo?"}
              onConfirm={() => handleDeleteTenant(objectId)}
              okText="Procedi"
              cancelText="Torna indietro"
            >
              <Button>
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          )}
        </div>
      );
    },
    [handleDeleteTenant, canDeleteTenants]
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Layout>
      <PageHeader
        title="Lista clienti"
        extra={
          canCreateTenants && (
            <Link to={navigationUtils.routes.tenant.create()}>
              <Button>{"Crea nuovo cliente"}</Button>
            </Link>
          )
        }
      />
      <Table dataSource={tenants.map(t => ({ ...t, key: t.objectId }))}>
        <Table.Column
          title="Nome"
          dataIndex="name"
          key="name"
          sorter={uiUtils.getSortFunction<Tenant>("name")}
          render={(name, tenant) => (
            <Link to={navigationUtils.routes.tenant.find(tenant.objectId)}>
              {name}
            </Link>
          )}
        />
        <Table.Column
          title="Crediti"
          dataIndex="credits"
          key="credits"
          sorter={uiUtils.getSortFunction<Tenant>("credits")}
        />
        <Table.Column
          title="Creato il"
          dataIndex="createdAt"
          key="createdAt"
          sorter={uiUtils.getSortFunction<Tenant>("createdAt")}
          render={uiUtils.prettifyDateTime}
        />
        <Table.Column
          title="Aggiornato al"
          dataIndex="updatedAt"
          key="updatedAt"
          sorter={uiUtils.getSortFunction<Tenant>("updatedAt")}
          render={uiUtils.prettifyDateTime}
        />
        <Table.Column
          dataIndex="objectId"
          key="objectId"
          render={renderItemActionButtons}
        />
      </Table>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(TenantListScreen));
