import React from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";

import navigationUtils from "../utils/navigation";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Spinner from "../components/Spinner";
import { Stores } from "../types/stores";

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  tenantId: string | null;
  museumId: string | null;
  isLoading: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const museumId = navigationUtils.fromRoutes.museumId(
    ownProps.location.pathname
  );
  return {
    tenantId,
    museumId,
    isLoading: false
  };
};

const MuseumSurveyScreen = ({
  tenantId,
  museumId,
  isLoading
}: OwnProps & StoresProps) => {
  if (isLoading || !tenantId || !museumId) {
    return <Spinner />;
  }

  return (
    <Layout style={{ height: "100%" }}>
      <PageHeader title="Sondaggio museo" />
      <h1>MuseumSurvey</h1>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(MuseumSurveyScreen));
