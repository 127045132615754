import React, { useCallback, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import uiUtils from "../utils/ui";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Layout from "../components/Layout";
import Link from "../components/Link";
import PageHeader from "../components/PageHeader";
import Popconfirm from "../components/Popconfirm";
import Spinner from "../components/Spinner";
import Table from "../components/Table";
import { Stores } from "../types/stores";
import { User } from "../types/user";

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  fetchUsers: () => Promise<void>;
  deleteUser: (userId: User["objectId"]) => Promise<boolean>;
  users: User[];
  isLoading: boolean;
}

const mapStoresToProps = (stores: Stores): StoresProps => {
  return {
    fetchUsers: stores.users.fetchUsers,
    deleteUser: stores.users.deleteUser,
    users: _.values(stores.users.users),
    isLoading: stores.users.isFetchingUsers
  };
};

const UserListScreen = ({
  fetchUsers,
  deleteUser,
  users,
  isLoading
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleDeleteUser = useCallback(
    (userId: string) => {
      deleteUser(userId).then(success => {
        if (success) {
          loggerService.success("Eliminazione avvenuta con successo");
        } else {
          loggerService.success("Eliminazione non riuscita");
        }
      });
    },
    [deleteUser]
  );

  const renderItemActionButtons = useCallback(
    (objectId: string) => {
      return (
        <div style={{ textAlign: "right" }}>
          <Popconfirm
            placement="leftTop"
            title={"Sei sicuro di eliminarlo?"}
            onConfirm={() => handleDeleteUser(objectId)}
            okText="Procedi"
            cancelText="Torna indietro"
          >
            <Button>
              <Icon type="delete" />
            </Button>
          </Popconfirm>
        </div>
      );
    },
    [handleDeleteUser]
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Layout>
      <PageHeader
        title="Lista utenti"
        extra={
          <Link to={navigationUtils.routes.user.create()}>
            <Button>{"Crea nuovo utente"}</Button>
          </Link>
        }
      />
      <Table dataSource={users.map(u => ({ ...u, key: u.objectId }))}>
        <Table.Column
          title="Email"
          dataIndex="email"
          key="email"
          sorter={uiUtils.getSortFunction<User>("email")}
          render={(email, user) => (
            <Link to={navigationUtils.routes.user.find(user.objectId)}>
              {email}
            </Link>
          )}
        />
        <Table.Column
          title="Nome"
          dataIndex="name"
          key="name"
          sorter={uiUtils.getSortFunction<User>("name")}
        />
        <Table.Column
          title="Cognome"
          dataIndex="surname"
          key="surname"
          sorter={uiUtils.getSortFunction<User>("surname")}
        />
        <Table.Column
          title="Cliente"
          dataIndex="tenant"
          key="tenant"
          sorter={uiUtils.getSortFunction<User>("tenant")}
          render={tenant => (tenant ? tenant.objectId : "")}
        />
        <Table.Column
          title="Creato il"
          dataIndex="createdAt"
          key="createdAt"
          sorter={uiUtils.getSortFunction<User>("createdAt")}
          render={uiUtils.prettifyDateTime}
        />
        <Table.Column
          title="Aggiornato al"
          dataIndex="updatedAt"
          key="updatedAt"
          sorter={uiUtils.getSortFunction<User>("updatedAt")}
          render={uiUtils.prettifyDateTime}
        />
        <Table.Column
          dataIndex="objectId"
          key="objectId"
          render={renderItemActionButtons}
        />
      </Table>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(UserListScreen));
