import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import objectUtils from "../utils/object";
import DescriptionEditor from "../components/DescriptionEditor";
import Form from "../components/Form";
import FormField from "../components/FormField";
// import GeolocationPicker from "../components/GeolocationPicker";
import Input from "../components/Input";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Spinner from "../components/Spinner";
import Tabs from "../components/Tabs";
import Upload from "../components/Upload";
import { Artwork } from "../types/artwork";
import { Language } from "../types/language";
import { Museum } from "../types/museum";
import { Stores } from "../types/stores";
import { UploadMultimedia } from "../components/UploadMultimedia";

const getFormValues = (artwork: Artwork | null, museum: Museum | null) => {
  return {
    ..._.omit(artwork, ["museum"]),
    museum
  };
};

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  tenantId: string | null;
  museumId: string | null;
  artworkId: string | null;
  museum: Museum | null;
  artwork: Artwork | null;
  fetchArtworks: () => Promise<void>;
  fetchMuseums: () => Promise<void>;
  createArtwork: (
    params: Partial<Artwork>,
    museumId: Museum["objectId"]
  ) => Promise<Artwork | null>;
  updateArtwork: (params: Partial<Artwork>) => Promise<Artwork | null>;
  generateMainAudioForArtwork: (
    artworkId: Artwork["objectId"],
    languageCode: string
  ) => Promise<boolean>;
  isCreatingArtwork: boolean;
  isLoading: boolean;
  canSaveArtworks: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const tenantId = navigationUtils.fromRoutes.tenantId(
    ownProps.location.pathname
  );
  const museumId = navigationUtils.fromRoutes.museumId(
    ownProps.location.pathname
  );
  const artworkId = navigationUtils.fromRoutes.artworkId(
    ownProps.location.pathname
  );
  const museum = (museumId && _.get(stores.museums.museums, museumId)) || null;
  const isCreatingArtwork =
    artworkId === navigationUtils.routesUtils.pathCreate;
  const artwork =
    (artworkId && _.get(stores.artworks.artworks, artworkId)) || null;
  const currentUserId = _.get(stores.auth.user, "objectId");
  const canSaveArtworks =
    stores.users.getIsAdmin(currentUserId) ||
    stores.users.getIsTenantAdmin(currentUserId, tenantId) ||
    stores.users.getIsMuseumAdmin(currentUserId, museumId) ||
    stores.users.getIsContentAdmin(currentUserId, museumId);
  return {
    tenantId,
    museumId,
    artworkId,
    artwork,
    museum,
    fetchArtworks: stores.artworks.fetchArtworks,
    fetchMuseums: stores.museums.fetchMuseums,
    createArtwork: stores.artworks.createArtwork,
    updateArtwork: stores.artworks.updateArtwork,
    generateMainAudioForArtwork: stores.artworks.generateMainAudioForArtwork,
    isCreatingArtwork,
    isLoading:
      stores.artworks.isFetchingArtworks ||
      stores.museums.isFetchingMuseums ||
      stores.artworks.isGeneratingAudio,
    canSaveArtworks
  };
};

const ArtworkDetailScreen = ({
  history,
  location,
  tenantId,
  museumId,
  artworkId,
  artwork,
  museum,
  fetchArtworks,
  fetchMuseums,
  createArtwork,
  updateArtwork,
  generateMainAudioForArtwork,
  isCreatingArtwork,
  isLoading,
  canSaveArtworks
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchArtworks();
    fetchMuseums();
  }, [fetchArtworks, fetchMuseums]);

  const [formValues, setFormValues] = useState(getFormValues(artwork, museum));

  useEffect(() => {
    setFormValues(getFormValues(artwork, museum));
  }, [setFormValues, artwork, museum]);

  const handleValidSubmit = useCallback(
    (values: any) => {
      if (!canSaveArtworks) {
        return loggerService.error("Salvataggio non autorizzato");
      }
      if (!tenantId || !museumId) {
        return loggerService.error("Errore nel salvataggio");
      }
      if (!isCreatingArtwork && !artwork) {
        return loggerService.error("Errore nel salvataggio");
      }
      const handleSaveArtwork = async () => {
        try {
          const params: any = objectUtils.pickOrUndefined(values, [
            "name",
            "tinyInfo",
            "coverImage",
            "blurb",
            "sections",
            "multimedia",
            "audio",
            "coords",
            "autoplayDistanceInMeters",
            "beacons"
          ]);
          if (artwork) {
            params.objectId = artwork.objectId;
          }
          let savedArtwork: Artwork | null = null;
          if (isCreatingArtwork) {
            // @ts-ignore
            savedArtwork = await createArtwork(params, museumId);
          } else {
            savedArtwork = await updateArtwork(params);
          }
          if (!savedArtwork) {
            return loggerService.error("Errore nel salvataggio");
          }
          loggerService.success("Salvataggio riuscito");
          navigationUtils.goTo(
            { history, location },
            navigationUtils.routes.artwork.find(
              tenantId,
              museumId,
              savedArtwork.objectId
            )
          );
        } catch (err) {
          loggerService.error("Errore nel salvataggio");
        }
      };
      return handleSaveArtwork();
    },
    [
      canSaveArtworks,
      isCreatingArtwork,
      artwork,
      createArtwork,
      updateArtwork,
      museumId,
      tenantId,
      history,
      location
    ]
  );

  const handleMainAudioGenerationRequest = useCallback(
    (language: string) => {
      const generateAudio = async () => {
        if (!artwork) {
          return;
        }
        const success = await generateMainAudioForArtwork(
          artwork.objectId,
          language
        );
        if (success) {
          loggerService.success("Generazione audio riuscita");
        } else {
          loggerService.error("Errore nella generazione audio");
        }
      };
      generateAudio();
    },
    [artwork, generateMainAudioForArtwork]
  );

  const [selectedLanguage, setSelectedLanguage] = useState("it");

  if (
    isLoading ||
    !tenantId ||
    !museumId ||
    !artworkId ||
    !museum ||
    (!artwork && !isCreatingArtwork)
  ) {
    return <Spinner />;
  }

  const languages: Language[] = _.get(museum, `languages`, []);
  const firstAvailableLanguage: Language | undefined = _.get(
    languages,
    "0",
    undefined
  );

  const pageTitle =
    _.get(artwork, `name.it`) ||
    (firstAvailableLanguage &&
      _.get(artwork, `name.${firstAvailableLanguage}`)) ||
    "Modifica punto di interesse";

  return (
    <Layout style={{ height: "100%" }}>
      <PageHeader
        title={isCreatingArtwork ? "Crea nuovo punto di interesse" : pageTitle}
      />
      <Form
        key={`${formValues.objectId}${_.get(formValues, "museum.objectId")}`}
        initialValues={formValues}
        onSubmit={handleValidSubmit}
        hasSubmitButton={canSaveArtworks}
      >
        <Tabs defaultActiveKey="it" onChange={setSelectedLanguage}>
          {languages.map(language => (
            <Tabs.TabPane tab={language} key={language} />
          ))}
        </Tabs>
        <FormField
          name={`museum.name.${firstAvailableLanguage}`}
          label="Museo di appartenenza"
          as={Input}
          type="text"
          disabled={true}
        />
        <FormField
          name={`name`}
          label="Titolo"
          as={Input}
          type="text"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`tinyInfo`}
          label="Mini informazione sotto il titolo"
          as={Input}
          type="text"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name="coverImage"
          label="Immagine di copertina"
          as={Upload}
          mimeTypes={["image/jpeg", "image/png"]}
          filesMaxCount={1}
        />
        <FormField
          name="multimedia"
          label="Immagini/video di repertorio"
          mimeTypes={[
            "image/jpeg",
            "image/png",
            "video/mp4",
            "audio/mp3",
            "audio/wav"
          ]}
          language={selectedLanguage}
          as={UploadMultimedia}
        />
        <FormField
          name={`blurb`}
          label="Breve descrizione"
          as={Input}
          type="textarea"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={"sections"}
          label="Paragrafi descrizione"
          as={DescriptionEditor}
          language={selectedLanguage}
          onMainAudioGenerationRequest={handleMainAudioGenerationRequest}
        />
        <FormField
          name={`audio.main.file`}
          label="File audio principale"
          as={Upload}
          mimeTypes={["audio/mpeg", "audio/mp3"]}
          filesMaxCount={1}
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`audio.main.title`}
          label="Titolo audio principale"
          as={Input}
          type="text"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`audio.main.artist`}
          label="Artista dell'audio principale"
          as={Input}
          type="text"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name="audio.main.image"
          label="Immagine per audio principale"
          mimeTypes={["image/jpeg", "image/png"]}
          as={Upload}
          filesMaxCount={1}
        />
        <FormField
          name={`audio.additional.file`}
          label="File audio secondario"
          mimeTypes={["audio/mpeg", "audio/mp3"]}
          as={Upload}
          filesMaxCount={1}
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`audio.additional.title`}
          label="Titolo audio secondario"
          as={Input}
          type="text"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name={`audio.additional.artist`}
          label="Artista dell'audio secondario"
          as={Input}
          type="text"
          fieldLanguages={languages}
          fieldSelectedLanguage={selectedLanguage}
        />
        <FormField
          name="audio.additional.image"
          label="Immagine per audio secondario"
          mimeTypes={["image/jpeg", "image/png"]}
          as={Upload}
          filesMaxCount={1}
        />
        {/* TODO: enable once feature implemented */}
        {/* <FormField
          key={`autoplayDistanceInMeters`}
          name={`autoplayDistanceInMeters`}
          label="Distanza di autoplay in metri (GPS)"
          as={Input}
          type={"number"}
          min={0}
        />
        <FormField
          name={`coords`}
          label="Coordinate (GPS)"
          as={GeolocationPicker}
        /> */}
      </Form>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(ArtworkDetailScreen));
