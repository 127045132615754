import React from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";

import stringsConfig from "../config/strings";
import navigationUtils from "../utils/navigation";
import Button from "../components/Button";
import Layout from "../components/Layout";
import Link from "../components/Link";
import Result from "../components/Result";
import { Stores } from "../types/stores";

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  return {};
};

const NotFoundScreen = (props: OwnProps & StoresProps) => {
  return (
    <Layout style={{ height: "100%" }}>
      <Result
        status="404"
        title="404"
        subTitle={stringsConfig.screens.notFound.resultDescription}
        extra={
          <Link to={navigationUtils.routes.home.main()}>
            <Button type="primary">
              {stringsConfig.screens.notFound.homeButton}
            </Button>
          </Link>
        }
      />
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(NotFoundScreen));
