import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Location, History } from "history";
import _ from "lodash";

import loggerService from "../services/logger";
import navigationUtils from "../utils/navigation";
import objectUtils from "../utils/object";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Input from "../components/Input";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Select from "../components/Select";
import Spinner from "../components/Spinner";
import UserRolesSelect from "../components/UserRolesSelect";
import { Stores } from "../types/stores";
import { Tenant } from "../types/tenant";
import { User } from "../types/user";

const getFormValues = (
  user: User | null,
  tenant: Tenant | null,
  currentUser: User | null,
  isCreatingUser: boolean,
  userRoles: string[]
) => {
  return {
    ..._.omit(user, ["tenant"]),
    tenantId: isCreatingUser
      ? _.get(currentUser, "tenant.objectId")
      : _.get(tenant, "objectId"),
    userRoles
  };
};

interface OwnProps {
  location: Location;
  history: History;
}

interface StoresProps {
  currentUser: User | null;
  user: User | null;
  userRoles: string[];
  tenant: Tenant | null;
  tenants: Tenant[];
  isCreatingUser: boolean;
  canEditUser: boolean;
  fetchUsers: () => Promise<void>;
  fetchTenants: () => Promise<void>;
  fetchMuseums: () => Promise<void>;
  createUser: (
    params: Partial<User>,
    tenantId: Tenant["objectId"]
  ) => Promise<User | null>;
  updateUser: (params: Partial<User>) => Promise<User | null>;
  addRoleToUser: (
    userId: User["objectId"],
    roleName: string
  ) => Promise<boolean>;
  removeRoleToUser: (
    userId: User["objectId"],
    roleName: string
  ) => Promise<boolean>;
  isLoading: boolean;
  isSaving: boolean;
}

const mapStoresToProps = (stores: Stores, ownProps: OwnProps): StoresProps => {
  const userId = navigationUtils.fromRoutes.userId(ownProps.location.pathname);
  const user = (userId && _.get(stores.users.users, userId)) || null;
  const userRoles = stores.users.getUserRoleNames(userId);
  const tenantId = _.get(user, "tenant.objectId");
  const tenant = (tenantId && _.get(stores.tenants.tenants, tenantId)) || null;
  const isCreatingUser = userId === navigationUtils.routesUtils.pathCreate;
  const canEditUser = true; // TODO
  return {
    currentUser: stores.auth.user,
    user,
    userRoles,
    tenant,
    tenants: Object.values(stores.tenants.tenants || {}),
    isCreatingUser,
    canEditUser,
    fetchUsers: stores.users.fetchUsers,
    fetchTenants: stores.tenants.fetchTenants,
    fetchMuseums: stores.museums.fetchMuseums,
    createUser: stores.users.createUser,
    updateUser: stores.users.updateUser,
    addRoleToUser: stores.users.addRoleToUser,
    removeRoleToUser: stores.users.removeRoleToUser,
    isLoading:
      stores.users.isFetchingUsers ||
      stores.tenants.isFetchingTenants ||
      stores.museums.isFetchingMuseums,
    isSaving: stores.users.isCreatingUsers || stores.users.isUpdatingUsers
  };
};

const UserDetailScreen = ({
  history,
  location,
  currentUser,
  user,
  userRoles,
  tenant,
  tenants,
  isCreatingUser,
  canEditUser,
  fetchUsers,
  fetchTenants,
  fetchMuseums,
  createUser,
  updateUser,
  addRoleToUser,
  removeRoleToUser,
  isLoading,
  isSaving
}: OwnProps & StoresProps) => {
  useEffect(() => {
    fetchUsers();
    fetchTenants();
    fetchMuseums();
  }, [fetchUsers, fetchTenants, fetchMuseums]);

  const [formValues, setFormValues] = useState(
    getFormValues(user, tenant, currentUser, isCreatingUser, userRoles)
  );

  useEffect(() => {
    setFormValues(
      getFormValues(user, tenant, currentUser, isCreatingUser, userRoles)
    );
  }, [setFormValues, user, tenant, currentUser, isCreatingUser, userRoles]);

  const handleValidSubmit = useCallback(
    (values: any) => {
      if (!canEditUser) {
        return loggerService.error(
          "Permessi insufficienti, salvataggio negato"
        );
      }
      if (!isCreatingUser && !user) {
        return loggerService.error("Errore nel salvataggio");
      }
      const handleSaveUser = async () => {
        try {
          const params: any = objectUtils.pickOrUndefined(values, [
            "email",
            "name",
            "surname"
          ]);
          if (_.isString(values.password) && values.password.trim() !== "") {
            params.password = values.password;
          }
          if (user) {
            params.objectId = user.objectId;
          }
          const userNextRoles: string[] = values.userRoles || [];
          const rolesToAdd = userNextRoles.filter(
            role => !userRoles.includes(role)
          );
          const rolesToRemove = userRoles.filter(
            role => !userNextRoles.includes(role)
          );
          let savedUser: User | null = null;
          if (isCreatingUser) {
            const tenantId: string | undefined = _.get(values, "tenantId");
            if (!tenantId) {
              return loggerService.error(
                "Impossibile creare utente non associato a un cliente"
              );
            }
            // @ts-ignore
            savedUser = await createUser(params, tenantId);
          } else {
            savedUser = await updateUser(params);
          }
          if (!savedUser) {
            return loggerService.error("Errore nel salvataggio");
          }
          const addRoleActions = [];
          const removeRoleActions = [];
          for (const roleName of rolesToRemove) {
            removeRoleActions.push(
              removeRoleToUser(savedUser.objectId, roleName)
            );
          }
          for (const roleName of rolesToAdd) {
            addRoleActions.push(addRoleToUser(savedUser.objectId, roleName));
          }
          await Promise.all([...addRoleActions, ...removeRoleActions]);
          loggerService.success("Utente salvato");
          navigationUtils.goTo(
            { history, location },
            navigationUtils.routes.user.find(savedUser.objectId)
          );
        } catch (err) {
          loggerService.error("Errore nel salvataggio");
        }
      };
      return handleSaveUser();
    },
    [
      isCreatingUser,
      user,
      userRoles,
      canEditUser,
      createUser,
      updateUser,
      addRoleToUser,
      removeRoleToUser,
      history,
      location
    ]
  );

  const handleFormValidation = useCallback((values: any) => {
    if (values.password && values.password !== values.repeatPassword) {
      return {
        repeatPassword: "Le password non corrispondono"
      };
    }
    return {};
  }, []);

  if (isLoading || !currentUser || (!user && !isCreatingUser)) {
    return <Spinner />;
  }

  const pageTitle =
    `${_.get(user, `name`)} ${_.get(user, `surname`)}`.trim() ||
    "Modifica utente";

  return (
    <Layout style={{ height: "100%" }}>
      <PageHeader title={isCreatingUser ? "Crea nuovo utente" : pageTitle} />
      <Form
        key={`${formValues.objectId}${_.get(formValues, "tenant.objectId")}`}
        initialValues={formValues}
        onSubmit={handleValidSubmit}
        validate={handleFormValidation}
        hasSubmitButton={canEditUser}
      >
        <FormField
          name="email"
          label="Email"
          as={Input}
          type="text"
          disabled={!isCreatingUser}
          required
        />
        <FormField
          name="tenantId"
          label="Cliente"
          as={Select}
          disabled={!isCreatingUser || !!currentUser.tenant}
          // @ts-ignore
          options={tenants
            .map(tenant => ({
              value: tenant.objectId,
              title: tenant.name
            }))
            .concat({
              value: undefined,
              title: "-"
            })}
        />
        <FormField name="name" label="Nome" as={Input} type="text" required />
        <FormField
          name="surname"
          label="Cognome"
          as={Input}
          type="text"
          required
        />
        <FormField
          name="password"
          label="Password"
          as={Input}
          type="password"
          required={isCreatingUser}
        />
        <FormField
          name="repeatPassword"
          label="Ripeti password"
          as={Input}
          type="password"
          required={isCreatingUser}
        />
        <FormField name="userRoles" label="Permessi" as={UserRolesSelect} />
      </Form>
    </Layout>
  );
};

export default inject(mapStoresToProps)(observer(UserDetailScreen));
