import React from "react";
import _ from "lodash";

import Button from "../Button";
import Icon from "../Icon";
import Input from "../Input";
import Styled from "./styled";
import { Language, Translated } from "../../types/language";

interface Ticket {
  name?: Translated<string | undefined>;
  price?: string;
  currency?: string;
}

const emptyTicket: Ticket = {
  name: undefined,
  price: undefined,
  currency: "€"
};

type Props = {
  value?: Ticket[];
  onChange?: (value: Ticket[]) => any;
  language: Language;
};

export default React.forwardRef(
  ({ value, onChange, language, ...restProps }: Props, ref: React.Ref<any>) => {
    const tickets: Ticket[] =
      _.isArray(value) && value.length > 0 ? value : [emptyTicket];
    const handleChange = (index: number, ticket: Ticket) => {
      const newTickets = tickets.slice();
      newTickets[index] = ticket;
      onChange && onChange(newTickets);
    };
    const handleDelete = (index: number) => {
      const newTickets = tickets.slice();
      newTickets.splice(index, 1);
      if (newTickets.length === 0) {
        newTickets.push(emptyTicket);
      }
      onChange && onChange(newTickets);
    };
    const handleAdd = () => {
      const newTickets = tickets.slice();
      newTickets.push(emptyTicket);
      onChange && onChange(newTickets);
    };
    return (
      <Styled.TicketsTable ref={ref}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Prezzo</th>
            <th>Valuta</th>
            <th>
              <Button onClick={handleAdd}>
                <Icon type="plus" />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket, index) => (
            <tr key={`${index}`}>
              <td>
                <Input
                  {...restProps}
                  value={ticket && ticket.name && ticket.name[language]}
                  onChange={(value: any) =>
                    handleChange(
                      index,
                      _.merge({}, ticket, {
                        name: {
                          [language]: value
                        }
                      })
                    )
                  }
                />
              </td>
              <td>
                <Input
                  {...restProps}
                  value={ticket && ticket.price}
                  onChange={(value: any) =>
                    handleChange(
                      index,
                      _.merge({}, ticket, {
                        price: value
                      })
                    )
                  }
                />
              </td>
              <td>
                <Input
                  {...restProps}
                  value={ticket && ticket.currency}
                  onChange={(value: any) =>
                    handleChange(
                      index,
                      _.merge({}, ticket, {
                        currency: value
                      })
                    )
                  }
                />
              </td>
              <td>
                <Button onClick={() => handleDelete(index)}>
                  <Icon type="delete" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Styled.TicketsTable>
    );
  }
);
